import React, { useState } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/cjs/styles/prism';

interface ApiExample {
  curl: string;
  python: string;
  node: string;
  endpoint: string;
  method: string;
  description: string;
}

const apiExamples: ApiExample[] = [
  {
    endpoint: '/detection',
    method: 'POST',
    description: 'Create a new detection record with an image for ATM monitoring',
    curl: `curl -X POST https://monitor.pixuate.ai/detection \\
  -H "Authorization: Bearer YOUR_API_KEY" \\
  -H "Content-Type: multipart/form-data" \\
  -F "image=@/path/to/your/image.jpg" \\
  -F "location=ATM_Location_123" \\
  -F "atmId=ATM_123"`,
    python: `import requests

files = {
    'image': ('image.jpg', open('/path/to/your/image.jpg', 'rb'), 'image/jpeg')
}
data = {
    'location': 'ATM_Location_123',
    'atmId': 'ATM_123'
}
headers = {
    'Authorization': 'Bearer YOUR_API_KEY'
}

response = requests.post(
    'https://monitor.pixuate.ai/detection',
    files=files,
    data=data,
    headers=headers
)
print(response.json())`,
    node: `const axios = require('axios');
const FormData = require('form-data');
const fs = require('fs');

const formData = new FormData();
formData.append('image', fs.createReadStream('/path/to/your/image.jpg'));
formData.append('location', 'ATM_Location_123');
formData.append('atmId', 'ATM_123');

const response = await axios.post(
  'https://monitor.pixuate.ai/detection',
  formData,
  {
    headers: {
      'Authorization': 'Bearer YOUR_API_KEY',
      ...formData.getHeaders()
    }
  }
);
console.log(response.data);`
  },
  {
    endpoint: '/detection',
    method: 'GET',
    description: 'List detection records with optional date range filtering',
    curl: `curl -X GET "https://monitor.pixuate.ai/detection?startDate=2024-01-01T00:00:00Z&endDate=2024-12-31T23:59:59Z&page=1&limit=10" \\
  -H "Authorization: Bearer YOUR_API_KEY"`,
    python: `import requests

params = {
    'startDate': '2024-01-01T00:00:00Z',
    'endDate': '2024-12-31T23:59:59Z',
    'page': 1,
    'limit': 10
}
headers = {
    'Authorization': 'Bearer YOUR_API_KEY'
}
response = requests.get(
    'https://monitor.pixuate.ai/detection',
    params=params,
    headers=headers
)
print(response.json())`,
    node: `const axios = require('axios');

const params = {
  startDate: '2024-01-01T00:00:00Z',
  endDate: '2024-12-31T23:59:59Z',
  page: 1,
  limit: 10
};

const response = await axios.get('https://monitor.pixuate.ai/detection', {
  params,
  headers: {
    'Authorization': 'Bearer YOUR_API_KEY'
  }
});
console.log(response.data);`
  }
];

const ApiTesting: React.FC = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<'curl' | 'python' | 'node'>('curl');

  const languages = [
    { id: 'curl', label: 'cURL', syntax: 'bash' },
    { id: 'python', label: 'Python', syntax: 'python' },
    { id: 'node', label: 'Node.js', syntax: 'javascript' }
  ] as const;

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">API Testing Examples</h1>
      
      <div className="mb-6">
        <div className="border-b border-gray-200 dark:border-gray-700">
          <nav className="-mb-px flex space-x-8">
            {languages.map(({ id, label }) => (
              <button
                key={id}
                onClick={() => setSelectedLanguage(id)}
                className={`
                  py-2 px-4 border-b-2 font-medium text-sm
                  ${selectedLanguage === id
                    ? 'border-primary-500 text-primary-600 dark:text-primary-400'
                    : 'border-transparent text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 dark:hover:border-gray-600'}
                `}
              >
                {label}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div className="space-y-8">
        {apiExamples.map((example, index) => (
          <div key={index} className="bg-white dark:bg-neutral-800 rounded-lg shadow-md overflow-hidden border border-gray-200 dark:border-gray-700">
            <div className="p-4 bg-gray-50 dark:bg-neutral-900 border-b border-gray-200 dark:border-gray-700">
              <div className="flex items-center justify-between">
                <div>
                  <span className={`
                    inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${example.method === 'GET' 
                      ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300' 
                      : 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300'}
                  `}>
                    {example.method}
                  </span>
                  <span className="ml-2 text-sm font-mono text-gray-700 dark:text-gray-300">{example.endpoint}</span>
                </div>
              </div>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{example.description}</p>
            </div>
            <div className="p-4">
              <SyntaxHighlighter
                language={languages.find(lang => lang.id === selectedLanguage)?.syntax}
                style={dracula}
                customStyle={{ margin: 0, borderRadius: '0.375rem' }}
              >
                {example[selectedLanguage]}
              </SyntaxHighlighter>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApiTesting;
