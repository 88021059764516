import React from 'react';
import { NavLink } from 'react-router-dom';
import { navigation } from '../../config/navigation';

export default function Navigation() {
  return (
    <nav className="flex flex-1 flex-col bg-white dark:bg-neutral-900 shadow-lg border-r border-primary/10">
      <div className="flex flex-1 flex-col px-4 py-4">
        <ul role="list" className="flex flex-1 flex-col gap-y-2">
          {navigation.map((item) => (
            <li key={item.name}>
              <NavLink
                to={item.href}
                className={({ isActive }) =>
                  `${
                    isActive
                      ? 'bg-gradient-to-r from-primary to-primary/90 text-white shadow-md'
                      : 'text-neutral-700 dark:text-neutral-300 hover:bg-primary-lighter dark:hover:bg-primary/10 hover:text-primary dark:hover:text-primary-light'
                  } group flex gap-x-3 rounded-xl p-3 text-sm leading-6 font-medium transition-all duration-200 ease-in-out`
                }
              >
                {({ isActive }) => (
                  <>
                    <item.icon
                      className={`${
                        isActive 
                          ? 'text-white' 
                          : 'text-neutral-400 dark:text-neutral-500 group-hover:text-primary dark:group-hover:text-primary-light'
                      } h-5 w-5 shrink-0 transition-all duration-200 ease-in-out ${
                        isActive ? 'transform scale-110' : ''
                      } text-primary dark:text-primary-light`}
                      aria-hidden="true"
                    />
                    {item.name}
                  </>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
        
        {/* Help section */}
        <div className="mt-auto pt-6">
          <div className="rounded-xl bg-gradient-to-br from-primary-lighter to-primary/5 dark:from-primary/20 dark:to-primary/5 p-4 shadow-sm">
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0 p-1.5 bg-white dark:bg-primary/20 rounded-lg shadow-sm">
                <svg 
                  className="h-5 w-5 text-primary dark:text-primary-light" 
                  viewBox="0 0 20 20" 
                  fill="currentColor"
                >
                  <path 
                    fillRule="evenodd" 
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a.75.75 0 000 1.5h.253a.25.25 0 01.244.304l-.459 2.066A1.75 1.75 0 0010.747 15H11a.75.75 0 000-1.5h-.253a.25.25 0 01-.244-.304l.459-2.066A1.75 1.75 0 009.253 9H9z" 
                    clipRule="evenodd" 
                  />
                </svg>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-primary-900 dark:text-primary-100">
                  Need Help?
                </h3>
                <div className="mt-1 text-xs text-primary-700 dark:text-primary-300">
                  <p>Check our documentation or contact support for assistance.</p>
                </div>
                <a 
                  href="#support" 
                  className="mt-2 inline-flex items-center text-xs font-medium text-primary hover:text-primary-dark dark:text-primary-light dark:hover:text-primary transition-colors duration-200"
                >
                  Contact Support
                  <svg 
                    className="ml-1 h-4 w-4 text-primary dark:text-primary-light" 
                    viewBox="0 0 20 20" 
                    fill="currentColor"
                  >
                    <path 
                      fillRule="evenodd" 
                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" 
                      clipRule="evenodd" 
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
