import React from 'react';

export default function UserManagement() {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-neutral-900">User Management</h1>
        <button className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500">
          Add User
        </button>
      </div>

      <div className="bg-white shadow-sm ring-1 ring-neutral-900/5 rounded-lg">
        <div className="p-6">
          <div className="text-center text-neutral-500">
            User management functionality coming soon...
          </div>
        </div>
      </div>
    </div>
  );
}
