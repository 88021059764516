import api from './api';

export interface AtmLog {
  id: string;
  location: string;
  atmId: string;
  imagePath?: string;
  personCount: number;
  helmetCount: number;
  maskCount: number;
  isLoitering: number;
  isCameraBlock: number;
  isSceneChange: number;
  hourObj?: string;
  dateObj?: string;
  createdAt: string;
}

export interface LogsResponse {
  data: AtmLog[];
  total: number;
  page: number;
  totalPages: number;
}

export interface LogsQuery {
  startDate?: Date;
  endDate?: Date;
  atmId?: string;
  location?: string;
  page?: number;
  limit?: number;
}

export const logService = {
  async getLogs(query: LogsQuery): Promise<LogsResponse> {
    const params = new URLSearchParams();
    
    if (query.page) params.append('page', query.page.toString());
    if (query.limit) params.append('limit', query.limit.toString());
    if (query.atmId) params.append('atmId', query.atmId);
    if (query.location) params.append('location', query.location);
    if (query.startDate) params.append('startDate', query.startDate.toISOString());
    if (query.endDate) params.append('endDate', query.endDate.toISOString());

    const response = await api.get(`/atms/logs?${params.toString()}`);
    return response.data;
  }
};
