import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { atmService } from '../../services/atmService';
import { ATM } from '../../types';
import {
  PencilIcon,
  TrashIcon,
  CheckCircleIcon,
  XCircleIcon,
  WrenchIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { Dialog } from '@headlessui/react';

const statusIcons = {
  active: <CheckCircleIcon className="h-5 w-5 text-green-500" />,
  inactive: <XCircleIcon className="h-5 w-5 text-red-500" />,
  maintenance: <WrenchIcon className="h-5 w-5 text-yellow-500" />,
};

const statusColors = {
  active: 'bg-green-50 text-green-700 ring-green-600/20',
  inactive: 'bg-red-50 text-red-700 ring-red-600/20',
  maintenance: 'bg-yellow-50 text-yellow-700 ring-yellow-600/20',
};

interface DeleteConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  atmId: string;
}

function DeleteConfirmation({ isOpen, onClose, onConfirm, atmId }: DeleteConfirmationProps) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm rounded-lg bg-white p-6">
          <div className="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <Dialog.Title className="mt-4 text-lg font-medium text-center text-gray-900">
            Delete ATM
          </Dialog.Title>
          <Dialog.Description className="mt-2 text-sm text-center text-gray-500">
            Are you sure you want to delete ATM {atmId}? This action cannot be undone.
          </Dialog.Description>

          <div className="mt-6 flex justify-center space-x-4">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              Delete
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

interface ATMListProps {
  onEdit: (atm: ATM) => void;
}

export default function ATMList({ onEdit }: ATMListProps) {
  const [deleteAtmId, setDeleteAtmId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { data: atms, isLoading, isError, error } = useQuery<ATM[]>({
    queryKey: ['atms'],
    queryFn: atmService.getAll,
  });

  const deleteMutation = useMutation({
    mutationFn: atmService.delete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['atms'] });
    },
  });

  const handleDelete = async (id: string) => {
    await deleteMutation.mutateAsync(id);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error loading ATMs</h3>
            <div className="mt-2 text-sm text-red-700">
              {error instanceof Error ? error.message : 'Please try again later'}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="overflow-hidden">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  ATM ID
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Location
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Status
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Description
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {atms?.map((atm) => (
                <tr key={atm.id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {atm.atmId}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{atm.location}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm">
                    <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${statusColors[atm.status]}`}>
                      {statusIcons[atm.status]}
                      <span className="ml-1.5 capitalize">{atm.status}</span>
                    </span>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{atm.description}</td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <div className="flex justify-end gap-2">
                      <button
                        onClick={() => onEdit(atm)}
                        className="text-primary hover:text-primary-dark"
                      >
                        <PencilIcon className="h-5 w-5" />
                        <span className="sr-only">Edit</span>
                      </button>
                      <button
                        onClick={() => setDeleteAtmId(atm.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <TrashIcon className="h-5 w-5" />
                        <span className="sr-only">Delete</span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <DeleteConfirmation
        isOpen={!!deleteAtmId}
        onClose={() => setDeleteAtmId(null)}
        onConfirm={() => deleteAtmId && handleDelete(deleteAtmId)}
        atmId={deleteAtmId || ''}
      />
    </div>
  );
}
