import React from 'react';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-primary-lighter border-t border-primary/10 py-4 px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between">
        <div className="text-sm text-primary/80">
          {currentYear} ATM Monitoring System. All rights reserved.
        </div>
        <div className="flex space-x-6">
          <a href="#" className="text-sm text-primary/70 hover:text-primary transition-colors duration-150">
            Privacy Policy
          </a>
          <a href="#" className="text-sm text-primary/70 hover:text-primary transition-colors duration-150">
            Terms of Service
          </a>
          <a href="#" className="text-sm text-primary/70 hover:text-primary transition-colors duration-150">
            Contact Support
          </a>
        </div>
      </div>
    </footer>
  );
}
