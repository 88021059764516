import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { PhotoIcon } from '@heroicons/react/24/outline';
import apiTestingService, { ATMMonitorResponse } from '../../services/apiTestingService';
import ImagePreview from '../common/ImagePreview';
import JsonViewer from '../common/JsonViewer';

export default function AtmMonitorTester() {
  const [selectedImages, setSelectedImages] = useState<{
    current: File | null;
    reference: File | null;
  }>({ current: null, reference: null });
  const [previewUrls, setPreviewUrls] = useState<{
    current: string | null;
    reference: string | null;
  }>({ current: null, reference: null });

  const mutation = useMutation({
    mutationFn: (formData: FormData) => apiTestingService.testAtmMonitor(formData),
  });

  const handleImageChange = (type: 'current' | 'reference') => (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImages(prev => ({ ...prev, [type]: file }));
      setPreviewUrls(prev => ({ ...prev, [type]: URL.createObjectURL(file) }));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedImages.current || !selectedImages.reference) return;

    const formData = new FormData();
    formData.append('currentImage', selectedImages.current);
    formData.append('referenceImage', selectedImages.reference);
    formData.append('atmId', (event.currentTarget.elements.namedItem('atmId') as HTMLInputElement).value);
    formData.append('location', (event.currentTarget.elements.namedItem('location') as HTMLInputElement).value);

    mutation.mutate(formData);
  };

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div>
            <label htmlFor="atmId" className="block text-sm font-medium text-neutral-700">
              ATM ID
            </label>
            <input
              type="text"
              name="atmId"
              id="atmId"
              required
              className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="location" className="block text-sm font-medium text-neutral-700">
              Location
            </label>
            <input
              type="text"
              name="location"
              id="location"
              required
              className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary focus:ring-primary sm:text-sm"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label className="block text-sm font-medium text-neutral-700">Current Image</label>
            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-neutral-300 px-6 pt-5 pb-6">
              <div className="space-y-1 text-center">
                <PhotoIcon className="mx-auto h-12 w-12 text-neutral-400" />
                <div className="flex text-sm text-neutral-600">
                  <label
                    htmlFor="current-image-upload"
                    className="relative cursor-pointer rounded-md bg-white font-medium text-primary hover:text-primary-dark focus-within:outline-none focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2"
                  >
                    <span>Upload current image</span>
                    <input
                      id="current-image-upload"
                      name="currentImage"
                      type="file"
                      accept="image/*"
                      required
                      className="sr-only"
                      onChange={handleImageChange('current')}
                    />
                  </label>
                </div>
                <p className="text-xs text-neutral-500">PNG, JPG, GIF up to 5MB</p>
              </div>
            </div>
            {previewUrls.current && (
              <div className="mt-4">
                <ImagePreview url={previewUrls.current} alt="Current image" />
              </div>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-neutral-700">Reference Image</label>
            <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-neutral-300 px-6 pt-5 pb-6">
              <div className="space-y-1 text-center">
                <PhotoIcon className="mx-auto h-12 w-12 text-neutral-400" />
                <div className="flex text-sm text-neutral-600">
                  <label
                    htmlFor="reference-image-upload"
                    className="relative cursor-pointer rounded-md bg-white font-medium text-primary hover:text-primary-dark focus-within:outline-none focus-within:ring-2 focus-within:ring-primary focus-within:ring-offset-2"
                  >
                    <span>Upload reference image</span>
                    <input
                      id="reference-image-upload"
                      name="referenceImage"
                      type="file"
                      accept="image/*"
                      required
                      className="sr-only"
                      onChange={handleImageChange('reference')}
                    />
                  </label>
                </div>
                <p className="text-xs text-neutral-500">PNG, JPG, GIF up to 5MB</p>
              </div>
            </div>
            {previewUrls.reference && (
              <div className="mt-4">
                <ImagePreview url={previewUrls.reference} alt="Reference image" />
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={mutation.isPending || !selectedImages.current || !selectedImages.reference}
            className="flex items-center gap-2 text-sm"
          >
            {mutation.isPending ? 'Testing...' : 'Test ATM Monitor'}
          </button>
        </div>
      </form>

      {mutation.data && (
        <div className="mt-6">
          <h3 className="text-lg font-medium text-neutral-900 mb-2">Response:</h3>
          <JsonViewer data={mutation.data} />
        </div>
      )}

      {mutation.error && (
        <div className="mt-4 rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="text-sm text-red-700">
              <p>Error: {(mutation.error as Error).message}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
