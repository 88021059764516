import React from 'react';

interface JsonViewerProps {
  data: any;
}

export default function JsonViewer({ data }: JsonViewerProps) {
  return (
    <div className="rounded-md bg-neutral-900 p-4 overflow-auto">
      <pre className="text-sm text-neutral-100">
        {JSON.stringify(data, null, 2)}
      </pre>
    </div>
  );
}
