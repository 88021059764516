import {
  HomeIcon,
  ChartBarIcon,
  VideoCameraIcon,
  Cog6ToothIcon,
  BuildingLibraryIcon,
  BeakerIcon,
  ClipboardDocumentListIcon,
  BookOpenIcon,
} from '@heroicons/react/24/outline';

export const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'ATM Management', href: '/atm-management', icon: BuildingLibraryIcon },
  { name: 'Monitoring', href: '/monitoring', icon: VideoCameraIcon },
  { name: 'Analytics', href: '/analytics', icon: ChartBarIcon },
  { name: 'Logs', href: '/logs', icon: ClipboardDocumentListIcon },
  { name: 'API Testing', href: '/api-testing', icon: BeakerIcon },
  { name: 'Docs', href: '/docs', icon: BookOpenIcon },
  { name: 'Settings', href: '/settings', icon: Cog6ToothIcon },
];
