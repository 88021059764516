import axios from 'axios';
import { config } from '../config/config';

const API_KEY = import.meta.env.VITE_API_KEY || 'ace3026290af7cd3481f68e44af6fc6280e05139e22f1e81a245b7e34dce88f3';
const BASE_URL = import.meta.env.VITE_API_URL || 'https://monitor.pixuate.ai/api/v1';

export interface DetectionResponse {
  success: boolean;
  data: {
    log: {
      id: string;
      location: string;
      atmId: string;
      imagePath: string;
      personCount: number;
      helmetCount: number;
      maskCount: number;
      faceCount: number;
      atmCount: number;
      personSitting: number;
      personLyingDown: number;
      vehicleCount: number;
      bagCount: number;
      isLoitering: number;
      isCameraBlock: number;
      isSceneChange: number;
      detections: {
        detections: Array<{
          label: string;
          score: number;
          box: number[];
          className: string;
        }>;
        counts: {
          people_count: number;
          helmet_count: number;
          mask_count: number;
          person_sitting: number;
          person_lying_down: number;
          face_count: number;
          atm_count: number;
        };
        alerts: {
          is_loitering: number;
          is_camera_block: number;
          is_scene_change: number;
        };
      };
      hourObj: string;
      dateObj: string;
      createdAt: string;
    };
    detections: Array<{
      label: string;
      score: number;
      box: number[];
      className: string;
    }>;
    counts: {
      people_count: number;
      helmet_count: number;
      mask_count: number;
      person_sitting: number;
      person_lying_down: number;
      face_count: number;
      atm_count: number;
    };
    alerts: {
      is_loitering: number;
      is_camera_block: number;
      is_scene_change: number;
    };
  };
}

export interface SceneChangeResponse {
  id: string;
  atmId: string;
  location: string;
  currentImageUrl: string;
  referenceImageUrl: string;
  changeScore: number;
  changes: Array<{
    type: string;
    confidence: number;
    region: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
  }>;
  createdAt: string;
  updatedAt: string;
}

export interface ATMMonitorResponse {
  id: string;
  atmId: string;
  location: string;
  currentImageUrl: string;
  referenceImageUrl: string;
  status: 'normal' | 'alert';
  alerts: Array<{
    type: string;
    severity: 'low' | 'medium' | 'high';
    description: string;
  }>;
  createdAt: string;
  updatedAt: string;
}

// Create axios instance with API key handling
const apiInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Accept': 'application/json',
    'X-API-Key': API_KEY // Add API key as header
  }
});

// Request interceptor to handle API key and FormData
apiInstance.interceptors.request.use((config) => {
  // Add API key as URL parameter and header
  const separator = config.url?.includes('?') ? '&' : '?';
  config.url = `${config.url}${separator}apiKey=${API_KEY}`;
  
  // Ensure API key is in headers
  if (config.headers) {
    config.headers['X-API-Key'] = API_KEY;
  }
  
  // Set correct Content-Type for FormData
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    config.headers['Content-Type'] = 'application/json';
  }
  
  return config;
});

const apiTestingService = {
  testDetection: async (formData: FormData): Promise<DetectionResponse> => {
    if (!API_KEY) {
      throw new Error('API key is not configured');
    }
    const response = await apiInstance.post('/api/v1/detection', formData);
    return response.data;
  },

  testSceneChange: async (formData: FormData): Promise<SceneChangeResponse> => {
    if (!API_KEY) {
      throw new Error('API key is not configured');
    }
    const response = await apiInstance.post('/api/v1/scene-change', formData);
    return response.data;
  },

  testAtmMonitor: async (formData: FormData): Promise<ATMMonitorResponse> => {
    if (!API_KEY) {
      throw new Error('API key is not configured');
    }
    const response = await apiInstance.post('/api/v1/atm-monitor', formData);
    return response.data;
  }
};

export default apiTestingService;
