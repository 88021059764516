import React, { useEffect, useState } from 'react';
import { DetectionData } from '../../types';
import { socketService } from '../../services/socketService';

interface LiveMonitoringGridProps {
  isMonitoringEnabled: boolean;
}

const LiveMonitoringGrid: React.FC<LiveMonitoringGridProps> = ({ isMonitoringEnabled }) => {
  const [detections, setDetections] = useState<Array<{ gridIndex: number; data: DetectionData }>>([]);
  const gridCount = 4;

  useEffect(() => {
    if (!isMonitoringEnabled) {
      setDetections([]);
      return;
    }

    const socket = socketService.connect();

    socket.on('detection', (data: DetectionData) => {
      setDetections(prev => {
        const randomGridIndex = Math.floor(Math.random() * gridCount);
        const newDetection = { gridIndex: randomGridIndex, data };
        
        // Keep only the last 4 detections
        const updatedDetections = [...prev, newDetection].slice(-gridCount);
        return updatedDetections;
      });
    });

    return () => {
      socket.off('detection');
      socketService.disconnect();
    };
  }, [isMonitoringEnabled]);

  const renderGrid = (index: number) => {
    const detection = detections.find(d => d.gridIndex === index);

    if (!detection) {
      return (
        <div className="h-full flex items-center justify-center bg-gray-50 rounded-lg">
          <p className="text-gray-500 text-sm">Waiting for detection...</p>
        </div>
      );
    }

    return (
      <div className="h-full p-4 bg-white rounded-lg shadow overflow-hidden">
        <div className="space-y-4">
          {detection.data.imagePath && (
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={`data:image/jpeg;base64,${detection.data.imagePath}`}
                alt="Detection"
                className="object-cover rounded-lg"
              />
            </div>
          )}
          <div className="space-y-2">
            <h3 className="text-sm font-medium text-gray-900">
              ATM {detection.data.atmId}
            </h3>
            <div className="bg-gray-50 p-2 rounded-md">
              <pre className="text-xs overflow-auto">
                {JSON.stringify(detection.data, null, 2)}
              </pre>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-2 gap-4">
      {Array.from({ length: gridCount }).map((_, index) => (
        <div key={index} className="h-96">
          {renderGrid(index)}
        </div>
      ))}
    </div>
  );
};

export default LiveMonitoringGrid;
