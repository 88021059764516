import React, { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import apiKeyService, { ApiKey } from '../../services/apiKeyService';

interface ApiKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  apiKey?: ApiKey | null;
}

const AVAILABLE_PERMISSIONS = [
  'read:detections',
  'write:detections',
  'read:analytics',
  'write:analytics',
  'read:settings',
  'write:settings',
];

const EXPIRY_OPTIONS = [
  { value: '7d', label: '7 days' },
  { value: '30d', label: '30 days' },
  { value: '90d', label: '90 days' },
  { value: '180d', label: '180 days' },
  { value: '365d', label: '1 year' },
  { value: 'never', label: 'Never' },
];

export default function ApiKeyModal({ isOpen, onClose, onSuccess, apiKey }: ApiKeyModalProps) {
  const [name, setName] = useState('');
  const [permissions, setPermissions] = useState<string[]>([]);
  const [expiresIn, setExpiresIn] = useState('30d');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [createdKey, setCreatedKey] = useState<string | null>(null);

  useEffect(() => {
    if (apiKey) {
      setName(apiKey.name);
      setPermissions(apiKey.permissions);
      setExpiresIn('30d'); // Default for editing
    } else {
      setName('');
      setPermissions([]);
      setExpiresIn('30d');
    }
    setError(null);
    setCreatedKey(null);
  }, [apiKey, isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (apiKey) {
        await apiKeyService.updateApiKey(apiKey.id, {
          name,
          permissions,
        });
      } else {
        const response = await apiKeyService.createApiKey({
          name,
          permissions,
          expiresIn,
        });
        setCreatedKey(response.key);
      }
      onSuccess();
      if (!apiKey) {
        setName('');
        setPermissions([]);
        setExpiresIn('30d');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save API key');
    } finally {
      setLoading(false);
    }
  };

  const handlePermissionToggle = (permission: string) => {
    setPermissions(prev =>
      prev.includes(permission)
        ? prev.filter(p => p !== permission)
        : [...prev, permission]
    );
  };

  const handleClose = () => {
    setCreatedKey(null);
    setError(null);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/30 dark:bg-black/50" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-xl w-full rounded-xl bg-white dark:bg-neutral-800 p-6 shadow-xl">
          <div className="flex items-center justify-between mb-6">
            <Dialog.Title className="text-lg font-semibold text-neutral-darker dark:text-neutral-lighter">
              {apiKey ? 'Edit API Key' : 'Create New API Key'}
            </Dialog.Title>
            <button
              type="button"
              onClick={handleClose}
              className="rounded-lg p-1 text-neutral hover:text-primary dark:text-neutral-light dark:hover:text-primary-light
                hover:bg-primary/5 dark:hover:bg-primary/10 transition-all duration-200"
            >
              <XMarkIcon className="h-5 w-5" />
            </button>
          </div>

          {error && (
            <div className="mb-4 p-3 rounded-lg bg-error/10 text-error text-sm">
              {error}
            </div>
          )}

          {createdKey ? (
            <div className="space-y-4">
              <div className="p-4 rounded-lg bg-success/10 border border-success/20">
                <h4 className="font-medium text-success mb-2">API Key Created Successfully</h4>
                <p className="text-sm text-neutral-dark dark:text-neutral-light mb-3">
                  Make sure to copy your API key now. You won't be able to see it again!
                </p>
                <div className="font-mono text-sm bg-white dark:bg-neutral-900 p-3 rounded border border-neutral/20 break-all">
                  {createdKey}
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleClose}
                  className="rounded-lg px-4 py-2 bg-primary text-white hover:bg-primary-dark
                    transition-colors duration-200"
                >
                  Done
                </button>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-neutral-darker dark:text-neutral-lighter mb-1">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full rounded-lg border border-neutral/20 dark:border-neutral/10 bg-white dark:bg-neutral-900
                    px-3 py-2 text-neutral-darker dark:text-neutral-lighter shadow-sm focus:border-primary dark:focus:border-primary-light
                    focus:outline-none focus:ring-1 focus:ring-primary dark:focus:ring-primary-light"
                  required
                />
              </div>

              {!apiKey && (
                <div>
                  <label htmlFor="expires" className="block text-sm font-medium text-neutral-darker dark:text-neutral-lighter mb-1">
                    Expires In
                  </label>
                  <select
                    id="expires"
                    value={expiresIn}
                    onChange={(e) => setExpiresIn(e.target.value)}
                    className="w-full rounded-lg border border-neutral/20 dark:border-neutral/10 bg-white dark:bg-neutral-900
                      px-3 py-2 text-neutral-darker dark:text-neutral-lighter shadow-sm focus:border-primary dark:focus:border-primary-light
                      focus:outline-none focus:ring-1 focus:ring-primary dark:focus:ring-primary-light"
                  >
                    {EXPIRY_OPTIONS.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-neutral-darker dark:text-neutral-lighter mb-2">
                  Permissions
                </label>
                <div className="grid grid-cols-2 gap-3">
                  {AVAILABLE_PERMISSIONS.map(permission => (
                    <label
                      key={permission}
                      className="flex items-center space-x-2 text-sm cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        checked={permissions.includes(permission)}
                        onChange={() => handlePermissionToggle(permission)}
                        className="rounded border-neutral/20 dark:border-neutral/10 text-primary 
                          focus:ring-primary dark:focus:ring-primary-light"
                      />
                      <span className="text-neutral-darker dark:text-neutral-lighter">{permission}</span>
                    </label>
                  ))}
                </div>
              </div>

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={handleClose}
                  className="rounded-lg px-4 py-2 text-neutral-darker dark:text-neutral-lighter hover:bg-neutral/10
                    dark:hover:bg-neutral/20 transition-colors duration-200"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading || !name || permissions.length === 0}
                  className="rounded-lg px-4 py-2 bg-primary text-white hover:bg-primary-dark
                    transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? 'Saving...' : apiKey ? 'Save Changes' : 'Create API Key'}
                </button>
              </div>
            </form>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
