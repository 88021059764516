import React, { Suspense } from 'react';
import ApiKeyList from '../components/settings/ApiKeyList';
import LicenseManagement from '../components/settings/LicenseManagement';

export default function Settings() {
  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-semibold text-neutral-darker dark:text-neutral-lighter">Settings</h2>
        <p className="mt-1 text-sm text-neutral dark:text-neutral-light">
          Manage your ATM network settings, license, and configurations
        </p>
      </div>

      <div className="grid grid-cols-1 gap-8">
        <Suspense fallback={<div className="text-center">Loading settings...</div>}>
          <LicenseManagement />
          <ApiKeyList />
        </Suspense>
      </div>
    </div>
  );
}
