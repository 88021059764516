import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { atmService } from '../../services/atmService';
import { MonitoringData } from '../../types';
import { formatDistanceToNow } from 'date-fns';

interface MonitoringGridProps {
  data: MonitoringData[];
}

const statusColors = {
  active: 'bg-green-100 text-green-800',
  inactive: 'bg-red-100 text-red-800',
  maintenance: 'bg-yellow-100 text-yellow-800',
};

const MonitoringGrid: React.FC<MonitoringGridProps> = ({ data }) => {
  const { isLoading, error } = useQuery({
    queryKey: ['atms'],
    queryFn: atmService.getAll,
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="text-red-500 flex items-center gap-2">
          <div className="text-gray-500">Error loading ATM data</div>
        </div>
      </div>
    );
  }

  if (!Array.isArray(data)) {
    console.error('MonitoringGrid received invalid data:', data);
    return (
      <div className="text-center py-4">
        <p className="text-gray-500">Error: Invalid monitoring data</p>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="text-center py-4">
        <p className="text-gray-500">No ATMs available for monitoring</p>
      </div>
    );
  }

  const formatDate = (dateString: string) => {
    try {
      // Ensure the date string is valid
      const date = new Date(dateString);
      if (isNaN(date.getTime())) {
        return 'Invalid date';
      }
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {data.map((item) => (
        <div
          key={item.id}
          className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200"
        >
          <div className="px-4 py-5 sm:px-6">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  ATM {item.atmId}
                </h3>
                <p className="mt-1 text-sm text-gray-500">{item.location}</p>
              </div>
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  statusColors[item.status as keyof typeof statusColors] || 'bg-gray-100 text-gray-800'
                }`}
              >
                {item.status}
              </span>
            </div>
          </div>
          <div className="px-4 py-4 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-4">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Last Update</dt>
                <dd className="mt-1 text-sm text-gray-900">{formatDate(item.lastUpdate)}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Alerts</dt>
                <dd className="mt-1 text-sm text-gray-900">{item.alerts}</dd>
              </div>
              {item.incidents && item.incidents.length > 0 && (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">Recent Incidents</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <ul className="divide-y divide-gray-200">
                      {item.incidents.slice(0, 3).map((incident, index) => (
                        <li key={index} className="py-2">
                          <p className="text-sm">{incident.description}</p>
                          <p className="text-xs text-gray-500">{formatDate(incident.timestamp)}</p>
                        </li>
                      ))}
                    </ul>
                  </dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MonitoringGrid;
