import { io, Socket } from 'socket.io-client';
import { DetectionData } from '../types';

type MonitoringTarget = string | 'all';

class SocketService {
  private socket: Socket | null = null;
  private monitoringEnabled: boolean = false;

  connect() {
    if (!this.socket) {
      this.socket = io(import.meta.env.VITE_BACKEND_URL || 'https://monitor.pixuate.ai', {
        transports: ['websocket'],
      });
    }
    return this.socket;
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  enableMonitoring(atmId: MonitoringTarget) {
    if (this.socket) {
      this.socket.emit('enable-monitoring', { atmId });
      this.monitoringEnabled = true;
    }
  }

  disableMonitoring(atmId: MonitoringTarget) {
    if (this.socket) {
      this.socket.emit('disable-monitoring', { atmId });
      this.monitoringEnabled = false;
    }
  }

  onDetection(callback: (data: DetectionData) => void) {
    if (this.socket) {
      this.socket.on('detection', callback);
    }
  }

  isConnected(): boolean {
    return this.socket?.connected || false;
  }

  isMonitoringEnabled() {
    return this.monitoringEnabled;
  }
}

export const socketService = new SocketService();
