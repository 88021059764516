import axios from 'axios';
import { config } from '../config/config';

const BASE_URL = import.meta.env.VITE_API_URL || 'https://monitor.pixuate.ai/api';

export interface LicenseStatus {
  isActive: boolean;
  maxAtmCount: number;
  currentAtmCount: number;
  expiryDate: string;
}

export interface LicenseActivationParams {
  licenseKey: string;
  validityDays: number;
  maxAtmCount: number;
}

export interface LicenseActivation {
  success: boolean;
  message: string;
  license: {
    maxAtmCount: number;
    expiryDate: string;
    isActive: boolean;
  };
}

export interface ATMRegistration {
  success: boolean;
  message: string;
}

const licenseService = {
  // Create axios instance with authorization header
  getInstance(token: string) {
    return axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  },

  // Get license status
  async getLicenseStatus(token: string): Promise<LicenseStatus> {
    const response = await this.getInstance(token).get('/license/status');
    return response.data;
  },

  // Activate license
  async activateLicense(token: string, params: LicenseActivationParams): Promise<LicenseActivation> {
    const response = await this.getInstance(token).post('/license/activate', { licenseKey: params.licenseKey });
    return response.data;
  },

  // Register ATM
  async registerATM(token: string, atmId: string): Promise<ATMRegistration> {
    const response = await this.getInstance(token).post('/license/register-atm', { atmId });
    return response.data;
  }
};

export default licenseService;
