import React, { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { ATM, CreateATMInput, ATMStatus } from '../../types';
import { atmService } from '../../services/atmService';
import { XMarkIcon, CameraIcon, MapPinIcon, KeyIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import ToggleSwitch from '../common/ToggleSwitch';
import { Dialog } from '@headlessui/react';
import { classNames } from '../../utils/styles';

interface ATMFormProps {
  atm?: ATM;
  onClose: () => void;
  onSuccess: () => void;
}

interface ATMConfigType {
  enableNotifications: boolean;
  alertThresholds: {
    loitering: number;
    crowding: number;
    peopleCount: number;
    suspiciousActivity: number;
  };
}

const defaultConfig: ATMConfigType = {
  enableNotifications: true,
  alertThresholds: {
    loitering: 300,
    crowding: 5,
    peopleCount: 10,
    suspiciousActivity: 0.8,
  },
};

interface FormDataType extends CreateATMInput {
  config: ATMConfigType;
}

export default function ATMForm({ atm, onClose, onSuccess }: ATMFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch
  } = useForm<FormDataType>({
    defaultValues: {
      atmId: atm?.atmId || '',
      location: atm?.location || '',
      status: atm?.status || 'active' as ATMStatus,
      description: atm?.description || '',
      config: atm?.config || defaultConfig,
    }
  });

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(atm?.image || null);
  const [dragActive, setDragActive] = useState(false);

  const createMutation = useMutation({
    mutationFn: atmService.create,
    onSuccess,
  });

  const updateMutation = useMutation({
    mutationFn: (data: FormDataType) => atmService.update(atm!.id, { id: atm!.id, ...data }),
    onSuccess,
  });

  const onSubmit = async (data: FormDataType) => {
    try {
      const submitData = { ...data };
      if (imageFile) {
        submitData.image = imageFile;
      }
      if (atm) {
        await updateMutation.mutateAsync(submitData);
      } else {
        await createMutation.mutateAsync(submitData);
      }
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrag = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Dialog open={true} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="bg-white dark:bg-neutral-800 rounded-xl shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
          <div className="p-6">
            <div className="flex justify-between items-center mb-6 border-b dark:border-neutral-700 pb-4">
              <Dialog.Title className="text-2xl font-semibold text-neutral-900 dark:text-white">
                {atm ? 'Edit ATM' : 'Add New ATM'}
              </Dialog.Title>
              <button 
                onClick={onClose}
                className="text-neutral-500 hover:text-neutral-700 dark:text-neutral-400 dark:hover:text-neutral-300 transition-colors"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-1">
                    <div className="flex items-center gap-2">
                      <KeyIcon className="h-4 w-4" />
                      ATM ID
                    </div>
                  </label>
                  <input
                    type="text"
                    {...register('atmId', { 
                      required: 'ATM ID is required',
                      pattern: {
                        value: /^[A-Za-z0-9-_]+$/,
                        message: 'ATM ID can only contain letters, numbers, hyphens, and underscores'
                      }
                    })}
                    className={classNames(
                      "mt-1 block w-full rounded-md shadow-sm sm:text-sm transition-colors",
                      "focus:ring-primary-500 focus:border-primary-500",
                      "dark:bg-neutral-900 dark:border-neutral-700 dark:text-white",
                      errors.atmId ? "border-red-300" : "border-neutral-300"
                    )}
                  />
                  {errors.atmId && (
                    <p className="mt-1 text-sm text-red-600 dark:text-red-400">{errors.atmId.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-1">
                    <div className="flex items-center gap-2">
                      <MapPinIcon className="h-4 w-4" />
                      Location
                    </div>
                  </label>
                  <input
                    type="text"
                    {...register('location', { required: 'Location is required' })}
                    className={classNames(
                      "mt-1 block w-full rounded-md shadow-sm sm:text-sm transition-colors",
                      "focus:ring-primary-500 focus:border-primary-500",
                      "dark:bg-neutral-900 dark:border-neutral-700 dark:text-white",
                      errors.location ? "border-red-300" : "border-neutral-300"
                    )}
                  />
                  {errors.location && (
                    <p className="mt-1 text-sm text-red-600 dark:text-red-400">{errors.location.message}</p>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-1">
                    Status
                  </label>
                  <select
                    {...register('status')}
                    className={classNames(
                      "mt-1 block w-full rounded-md shadow-sm sm:text-sm transition-colors",
                      "focus:ring-primary-500 focus:border-primary-500",
                      "dark:bg-neutral-900 dark:border-neutral-700 dark:text-white",
                      "border-neutral-300"
                    )}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="maintenance">Maintenance</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-1">
                    <div className="flex items-center gap-2">
                      <InformationCircleIcon className="h-4 w-4" />
                      Description
                    </div>
                  </label>
                  <textarea
                    {...register('description')}
                    rows={3}
                    className={classNames(
                      "mt-1 block w-full rounded-md shadow-sm sm:text-sm transition-colors",
                      "focus:ring-primary-500 focus:border-primary-500",
                      "dark:bg-neutral-900 dark:border-neutral-700 dark:text-white",
                      "border-neutral-300"
                    )}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-1">
                  <div className="flex items-center gap-2">
                    <CameraIcon className="h-4 w-4" />
                    ATM Image
                  </div>
                </label>
                <div 
                  className={classNames(
                    "mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-lg transition-colors",
                    dragActive 
                      ? "border-primary-500 bg-primary-50 dark:bg-primary-900/20" 
                      : "border-neutral-300 dark:border-neutral-700",
                    "dark:bg-neutral-900"
                  )}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                >
                  <div className="space-y-1 text-center">
                    {previewUrl ? (
                      <div className="relative group">
                        <img 
                          src={previewUrl} 
                          alt="ATM Preview" 
                          className="mx-auto h-32 w-32 object-cover rounded-lg"
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg flex items-center justify-center">
                          <p className="text-white text-sm">Click or drag to change</p>
                        </div>
                      </div>
                    ) : (
                      <CameraIcon className="mx-auto h-12 w-12 text-neutral-400" />
                    )}
                    <div className="flex text-sm text-neutral-600 dark:text-neutral-400">
                      <label className="relative cursor-pointer rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
                        <span>Upload a file</span>
                        <input
                          type="file"
                          className="sr-only"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs text-neutral-500 dark:text-neutral-400">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="text-lg font-medium text-neutral-900 dark:text-white">Alert Configuration</h3>
                
                <div className="bg-neutral-50 dark:bg-neutral-900 p-4 rounded-lg space-y-4">
                  <div className="flex items-center justify-between">
                    <span className="text-sm font-medium text-neutral-700 dark:text-neutral-300">
                      Enable Notifications
                    </span>
                    <ToggleSwitch
                      enabled={watch('config.enableNotifications')}
                      onChange={(enabled) => setValue('config.enableNotifications', enabled)}
                      label=""
                    />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-1">
                        Loitering Threshold (seconds)
                      </label>
                      <input
                        type="number"
                        {...register('config.alertThresholds.loitering', {
                          min: { value: 0, message: 'Must be greater than 0' },
                          max: { value: 3600, message: 'Must be less than 3600 seconds' }
                        })}
                        className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-1">
                        Crowding Threshold (people)
                      </label>
                      <input
                        type="number"
                        {...register('config.alertThresholds.crowding', {
                          min: { value: 1, message: 'Must be at least 1' },
                          max: { value: 50, message: 'Must be less than 50' }
                        })}
                        className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-1">
                        People Count Threshold
                      </label>
                      <input
                        type="number"
                        {...register('config.alertThresholds.peopleCount', {
                          min: { value: 1, message: 'Must be at least 1' },
                          max: { value: 100, message: 'Must be less than 100' }
                        })}
                        className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-1">
                        Suspicious Activity Threshold
                      </label>
                      <input
                        type="number"
                        step="0.1"
                        {...register('config.alertThresholds.suspiciousActivity', {
                          min: { value: 0, message: 'Must be between 0 and 1' },
                          max: { value: 1, message: 'Must be between 0 and 1' }
                        })}
                        className="mt-1 block w-full rounded-md border-neutral-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end space-x-3 pt-4 border-t dark:border-neutral-700">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-neutral-700 dark:text-neutral-300 hover:text-neutral-800 dark:hover:text-white transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={classNames(
                    "px-4 py-2 rounded-md text-sm font-medium text-white",
                    "bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500",
                    "disabled:opacity-50 disabled:cursor-not-allowed",
                    "transition-colors duration-200"
                  )}
                >
                  {isSubmitting ? 'Saving...' : atm ? 'Update ATM' : 'Create ATM'}
                </button>
              </div>
            </form>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
