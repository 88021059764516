import React, { useState } from 'react';
import ATMList from '../components/atm/ATMList';
import ATMForm from '../components/atm/ATMForm';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';

export default function ATMManagement() {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleSuccess = () => {
    setIsFormOpen(false);
    queryClient.invalidateQueries({ queryKey: ['atms'] });
  };

  return (
    <div className="min-h-screen bg-background-light">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-bold text-primary-darker">ATM Management</h1>
            <p className="mt-2 text-sm text-primary-dark">
              Manage and configure your ATM network settings and parameters
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={() => setIsFormOpen(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              New ATM
            </button>
          </div>
        </div>
        
        <div className="bg-white shadow-lg rounded-xl border-2 border-primary/10 p-6">
          <ATMList onEdit={() => setIsFormOpen(true)} />
        </div>

        {isFormOpen && (
          <ATMForm
            onClose={() => setIsFormOpen(false)}
            onSuccess={handleSuccess}
          />
        )}
      </div>
    </div>
  );
}
