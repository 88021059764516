import { api } from './api';
import { ATM } from '../types';

export interface CreateATMInput {
  atmId: string;
  location: string;
  status: string;
  description?: string;
  image?: File;
  config?: {
    enableNotifications: boolean;
    alertThresholds: {
      loitering: number;
      crowding: number;
      peopleCount: number;
      suspiciousActivity: number;
    };
  };
}

export interface UpdateATMInput extends Partial<CreateATMInput> {
  id: string;
}

const defaultConfig = {
  enableNotifications: true,
  alertThresholds: {
    loitering: 300,
    crowding: 5,
    peopleCount: 10,
    suspiciousActivity: 0.8,
  },
};

export const atmService = {
  getAll: async (): Promise<ATM[]> => {
    try {
      console.log('Fetching ATMs from:', api.defaults.baseURL);
      const response = await api.get('/atms');
      console.log('ATM response:', response.data);
      // Ensure we're returning an array with valid config for each ATM
      return Array.isArray(response.data) ? response.data.map(atm => ({
        ...atm,
        config: atm.config || defaultConfig
      })) : [];
    } catch (error) {
      console.error('Error fetching ATMs:', error);
      throw error;
    }
  },

  getById: async (id: string): Promise<ATM> => {
    try {
      console.log('Fetching ATM from:', api.defaults.baseURL);
      const response = await api.get(`/atms/${id}`);
      console.log('ATM response:', response.data);
      return {
        ...response.data,
        config: response.data.config || defaultConfig
      };
    } catch (error) {
      console.error('Error fetching ATM:', error);
      throw error;
    }
  },

  create: async (data: CreateATMInput): Promise<ATM> => {
    try {
      console.log('Creating ATM at:', api.defaults.baseURL);
      const formData = new FormData();
      const dataWithConfig = {
        ...data,
        config: data.config || defaultConfig
      };
      
      Object.entries(dataWithConfig).forEach(([key, value]) => {
        if (key === 'config') {
          formData.append(key, JSON.stringify(value));
        } else if (key === 'image' && value instanceof File) {
          formData.append(key, value);
        } else if (value !== undefined && (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')) {
          formData.append(key, String(value));
        }
      });

      const response = await api.post('/atms', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('ATM creation response:', response.data);
      return {
        ...response.data,
        config: response.data.config || defaultConfig
      };
    } catch (error) {
      console.error('Error creating ATM:', error);
      throw error;
    }
  },

  update: async (id: string, data: UpdateATMInput): Promise<ATM> => {
    try {
      console.log('Updating ATM at:', api.defaults.baseURL);
      const formData = new FormData();
      const dataWithConfig = {
        ...data,
        config: data.config || defaultConfig
      };

      Object.entries(dataWithConfig).forEach(([key, value]) => {
        if (key === 'config') {
          formData.append(key, JSON.stringify(value));
        } else if (key === 'image' && value instanceof File) {
          formData.append(key, value);
        } else if (value !== undefined && (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')) {
          formData.append(key, String(value));
        }
      });

      const response = await api.put(`/atms/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('ATM update response:', response.data);
      return {
        ...response.data,
        config: response.data.config || defaultConfig
      };
    } catch (error) {
      console.error('Error updating ATM:', error);
      throw error;
    }
  },

  delete: async (id: string): Promise<void> => {
    try {
      console.log('Deleting ATM at:', api.defaults.baseURL);
      await api.delete(`/atms/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      console.log('ATM deleted successfully');
    } catch (error: any) {
      console.error('Error deleting ATM:', error.response?.data || error.message);
      throw error;
    }
  },
};
