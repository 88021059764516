import React, { useEffect, useRef, useState } from 'react';

interface Detection {
  label: string;
  score: number;
  box: number[];
  className: string;
}

interface ImagePreviewProps {
  url: string;
  alt: string;
  detections?: Detection[];
}

export default function ImagePreview({ url, alt, detections }: ImagePreviewProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [naturalDimensions, setNaturalDimensions] = useState({ width: 0, height: 0 });
  const [displayDimensions, setDisplayDimensions] = useState({ width: 0, height: 0 });

  const updateDisplayDimensions = () => {
    const image = imageRef.current;
    if (!image) return;

    setDisplayDimensions({
      width: image.offsetWidth,
      height: image.offsetHeight
    });
  };

  // Get natural image dimensions on load
  useEffect(() => {
    const image = imageRef.current;
    if (!image) return;

    const handleLoad = () => {
      setNaturalDimensions({
        width: image.naturalWidth,
        height: image.naturalHeight
      });
      updateDisplayDimensions();
    };

    image.addEventListener('load', handleLoad);
    if (image.complete) handleLoad();

    return () => image.removeEventListener('load', handleLoad);
  }, [url]);

  // Update display dimensions on resize
  useEffect(() => {
    const observer = new ResizeObserver(updateDisplayDimensions);
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    updateDisplayDimensions();
    window.addEventListener('resize', updateDisplayDimensions);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', updateDisplayDimensions);
    };
  }, []);

  // Draw detection boxes
  useEffect(() => {
    if (!canvasRef.current || !detections || !displayDimensions.width || !displayDimensions.height) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Original image dimensions (from API)
    const ORIGINAL_WIDTH = 640;
    const ORIGINAL_HEIGHT = 640;

    // Set canvas size to match display dimensions
    canvas.width = displayDimensions.width;
    canvas.height = displayDimensions.height;

    // Clear previous drawings
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate scaling factors
    const imageAspectRatio = ORIGINAL_WIDTH / ORIGINAL_HEIGHT;
    const containerAspectRatio = displayDimensions.width / displayDimensions.height;

    let drawWidth = displayDimensions.width;
    let drawHeight = displayDimensions.height;

    // Calculate actual drawing dimensions considering object-fit: contain
    if (imageAspectRatio > containerAspectRatio) {
      // Image is wider than container
      drawHeight = drawWidth / imageAspectRatio;
    } else {
      // Image is taller than container
      drawWidth = drawHeight * imageAspectRatio;
    }

    // Calculate offset to center the image
    const offsetX = (displayDimensions.width - drawWidth) / 2;
    const offsetY = (displayDimensions.height - drawHeight) / 2;

    // Draw detection boxes
    detections.forEach(detection => {
      const [x1, y1, x2, y2] = detection.box;
      
      // Convert coordinates to percentages of original 640x640 image
      const percentX1 = x1 / ORIGINAL_WIDTH;
      const percentY1 = y1 / ORIGINAL_HEIGHT;
      const percentX2 = x2 / ORIGINAL_WIDTH;
      const percentY2 = y2 / ORIGINAL_HEIGHT;

      // Convert percentages to actual display coordinates
      const displayX1 = offsetX + percentX1 * drawWidth;
      const displayY1 = offsetY + percentY1 * drawHeight;
      const displayX2 = offsetX + percentX2 * drawWidth;
      const displayY2 = offsetY + percentY2 * drawHeight;

      // Draw box
      ctx.strokeStyle = getColorForLabel(detection.label);
      ctx.lineWidth = 2;
      ctx.strokeRect(
        displayX1,
        displayY1,
        displayX2 - displayX1,
        displayY2 - displayY1
      );

      // Draw label
      ctx.fillStyle = ctx.strokeStyle;
      ctx.font = '12px sans-serif';
      const label = `${detection.label} ${Math.round(detection.score * 100)}%`;
      const textWidth = ctx.measureText(label).width;
      
      // Draw label background
      ctx.fillRect(displayX1, displayY1 - 20, textWidth + 10, 20);
      
      // Draw label text
      ctx.fillStyle = '#FFFFFF';
      ctx.fillText(label, displayX1 + 5, displayY1 - 5);
    });
  }, [detections, displayDimensions]);

  const getColorForLabel = (label: string): string => {
    const colors: { [key: string]: string } = {
      'PERSON': '#FF0000',
      'FACE': '#00FF00',
      'HEAD': '#0000FF',
      'HALF_BODY': '#FFA500',
      'ATM_MACHINE': '#800080'
    };
    return colors[label] || '#FFFFFF';
  };

  return (
    <div ref={containerRef} className="relative rounded-lg border border-neutral-300 overflow-hidden aspect-video">
      <img
        ref={imageRef}
        src={url}
        alt={alt}
        className="w-full h-full object-contain"
      />
      <canvas
        ref={canvasRef}
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
      />
    </div>
  );
}
