import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { api } from '../services/api';
import { LineChart, BarChart } from '../components/charts';

interface DailyAnalytics {
  id: string;
  atmId: string;
  date: string;
  personCount: number;
  helmetCount: number;
  maskCount: number;
  faceCount: number;
  atmCount: number;
  personSitting: number;
  personLyingDown: number;
  vehicleCount: number;
  bagCount: number;
  loiteringCount: number;
  cameraBlockCount: number;
  sceneChangeCount: number;
  createdAt: string;
  updatedAt: string;
}

interface HourlyAnalytics {
  id: string;
  atmId: string;
  hour: string;
  personCount: number;
  helmetCount: number;
  maskCount: number;
  faceCount: number;
  atmCount: number;
  personSitting: number;
  personLyingDown: number;
  vehicleCount: number;
  bagCount: number;
  loiteringCount: number;
  cameraBlockCount: number;
  sceneChangeCount: number;
  createdAt: string;
  updatedAt: string;
}

interface IncidentCount {
  _count: number;
  severity: string;
}

interface ChartData {
  hourlyTrends: {
    hour: string;
    personCount: number;
    helmetCount: number;
    maskCount: number;
  }[];
  dailyTrends: {
    date: string;
    personCount: number;
    helmetCount: number;
    maskCount: number;
  }[];
  detectionTypes: {
    type: string;
    count: number;
  }[];
}

interface AnalyticsData {
  daily: DailyAnalytics | null;
  hourly: HourlyAnalytics | null;
  incidents: IncidentCount[];
  charts: ChartData;
}

export default function Analytics() {
  const { data: analyticsData, isLoading, isError, error } = useQuery<AnalyticsData>({
    queryKey: ['analytics'],
    queryFn: async () => {
      try {
        const response = await api.get('/analytics/overview');
        return response.data;
      } catch (err) {
        console.error('Error fetching analytics:', err);
        throw err;
      }
    },
    refetchInterval: 300000, // Refetch every 5 minutes
  });

  if (isLoading) {
    return (
      <div className="min-h-screen bg-background-light">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex items-center justify-center min-h-[400px]">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="min-h-screen bg-background-light">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="bg-red-50 p-4 rounded-md">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error loading analytics</h3>
                <div className="mt-2 text-sm text-red-700">
                  {error instanceof Error ? error.message : 'Please try again later'}
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="text-red-800 hover:text-red-600"
                    onClick={() => window.location.reload()}
                  >
                    Retry
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!analyticsData) {
    return (
      <div className="min-h-screen bg-background-light">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="text-center">
            <h3 className="text-lg font-medium text-gray-900">No data available</h3>
            <p className="mt-1 text-sm text-gray-500">Please try again later</p>
          </div>
        </div>
      </div>
    );
  }

  const { daily, hourly, incidents, charts } = analyticsData;

  return (
    <div className="min-h-screen bg-background-light">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Daily Analytics */}
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Daily Analytics</h2>
          {daily ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Person Count</h3>
                <p className="mt-1 text-2xl font-semibold">{daily.personCount}</p>
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Helmet Count</h3>
                <p className="mt-1 text-2xl font-semibold">{daily.helmetCount}</p>
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Mask Count</h3>
                <p className="mt-1 text-2xl font-semibold">{daily.maskCount}</p>
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Scene Changes</h3>
                <p className="mt-1 text-2xl font-semibold">{daily.sceneChangeCount}</p>
              </div>
            </div>
          ) : (
            <p className="text-gray-500">No daily analytics available</p>
          )}
        </div>

        {/* Charts */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
          {/* Hourly Trends */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Last 24 Hours Trends</h2>
            <LineChart
              data={charts.hourlyTrends}
              xKey="hour"
              yKeys={['personCount', 'helmetCount', 'maskCount']}
              colors={['#3B82F6', '#10B981', '#F59E0B']}
              labels={['Persons', 'Helmets', 'Masks']}
            />
          </div>

          {/* Daily Trends */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Last 30 Days Trends</h2>
            <LineChart
              data={charts.dailyTrends}
              xKey="date"
              yKeys={['personCount', 'helmetCount', 'maskCount']}
              colors={['#3B82F6', '#10B981', '#F59E0B']}
              labels={['Persons', 'Helmets', 'Masks']}
            />
          </div>

          {/* Detection Types Distribution */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Detection Types</h2>
            <BarChart
              data={charts.detectionTypes}
              xKey="type"
              yKey="count"
              color="#8B5CF6"
            />
          </div>

          {/* Incidents Distribution */}
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-4">Incidents by Severity</h2>
            <BarChart
              data={incidents.map(i => ({ type: i.severity, count: i._count }))}
              xKey="type"
              yKey="count"
              color="#EF4444"
            />
          </div>
        </div>

        {/* Hourly Analytics */}
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Current Hour Analytics</h2>
          {hourly ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Person Count</h3>
                <p className="mt-1 text-2xl font-semibold">{hourly.personCount}</p>
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Helmet Count</h3>
                <p className="mt-1 text-2xl font-semibold">{hourly.helmetCount}</p>
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Mask Count</h3>
                <p className="mt-1 text-2xl font-semibold">{hourly.maskCount}</p>
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">Scene Changes</h3>
                <p className="mt-1 text-2xl font-semibold">{hourly.sceneChangeCount}</p>
              </div>
            </div>
          ) : (
            <p className="text-gray-500">No hourly analytics available</p>
          )}
        </div>

        {/* Incidents */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-4">Incidents</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {incidents.map((incident) => (
              <div key={incident.severity} className="p-4 bg-gray-50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-500">{incident.severity} Severity</h3>
                <p className="mt-1 text-2xl font-semibold">{incident._count}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
