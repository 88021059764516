import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { PhotoIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import apiTestingService, { DetectionResponse } from '../../services/apiTestingService';
import ImagePreview from '../common/ImagePreview';
import JsonViewer from '../common/JsonViewer';

export default function DetectionTester() {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: (formData: FormData) => apiTestingService.testDetection(formData),
  });

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!selectedImage) return;

    const formData = new FormData();
    formData.append('image', selectedImage);
    formData.append('atmId', (event.currentTarget.elements.namedItem('atmId') as HTMLInputElement).value);
    formData.append('location', (event.currentTarget.elements.namedItem('location') as HTMLInputElement).value);

    mutation.mutate(formData);
  };

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="atmId" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
              ATM ID
            </label>
            <input
              type="text"
              name="atmId"
              id="atmId"
              required
              className="mt-1 block w-full rounded-md border-neutral-300 dark:border-neutral-600 bg-white dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:focus:border-primary-400 dark:focus:ring-primary-400 sm:text-sm"
              placeholder="Enter ATM ID"
            />
          </div>

          <div>
            <label htmlFor="location" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
              Location
            </label>
            <input
              type="text"
              name="location"
              id="location"
              required
              className="mt-1 block w-full rounded-md border-neutral-300 dark:border-neutral-600 bg-white dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100 shadow-sm focus:border-primary-500 focus:ring-primary-500 dark:focus:border-primary-400 dark:focus:ring-primary-400 sm:text-sm"
              placeholder="Enter location"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">Image</label>
          <div className="mt-1 flex justify-center rounded-lg border-2 border-dashed border-neutral-300 dark:border-neutral-600 px-6 pt-5 pb-6 transition-colors hover:border-primary-400 dark:hover:border-primary-500">
            <div className="space-y-2 text-center">
              <PhotoIcon className="mx-auto h-12 w-12 text-neutral-400 dark:text-neutral-500" />
              <div className="flex text-sm text-neutral-600 dark:text-neutral-400">
                <label
                  htmlFor="image-upload"
                  className="relative cursor-pointer rounded-md bg-transparent font-medium text-primary-600 dark:text-primary-400 hover:text-primary-500 dark:hover:text-primary-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-500 focus-within:ring-offset-2 dark:focus-within:ring-offset-neutral-800"
                >
                  <span>Upload an image</span>
                  <input
                    id="image-upload"
                    name="image"
                    type="file"
                    accept="image/*"
                    required
                    className="sr-only"
                    onChange={handleImageChange}
                  />
                </label>
                <p className="pl-1">or drag and drop</p>
              </div>
              <p className="text-xs text-neutral-500 dark:text-neutral-400">PNG, JPG, GIF up to 5MB</p>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={mutation.isPending}
            className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {mutation.isPending ? 'Processing...' : 'Process Image'}
          </button>
        </div>
      </form>

      {previewUrl && (
        <div className="mt-4 grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Left side - Image Preview */}
          <div className="rounded-lg border border-neutral-200 dark:border-neutral-700 p-4 bg-neutral-50 dark:bg-neutral-800/50">
            <h4 className="text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-2">Preview:</h4>
            <ImagePreview 
              url={previewUrl} 
              alt="Selected image" 
              detections={mutation.data?.data?.detections || []}
            />
          </div>

          {/* Right side - Detection Results */}
          <div className="rounded-lg border border-neutral-200 dark:border-neutral-700 p-4 bg-neutral-50 dark:bg-neutral-800/50">
            <h4 className="text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-2">Detection Results:</h4>
            {mutation.data && (
              <div className="space-y-4">
                {/* Counts */}
                <div>
                  <h5 className="text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-2">Counts:</h5>
                  <div className="grid grid-cols-2 gap-2">
                    <div>People: {mutation.data.data.counts.people_count}</div>
                    <div>Faces: {mutation.data.data.counts.face_count}</div>
                    <div>ATMs: {mutation.data.data.counts.atm_count}</div>
                    <div>Helmets: {mutation.data.data.counts.helmet_count}</div>
                    <div>Masks: {mutation.data.data.counts.mask_count}</div>
                  </div>
                </div>

                {/* Alerts */}
                <div>
                  <h5 className="text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-2">Alerts:</h5>
                  <div className="grid grid-cols-1 gap-2">
                    <div>Loitering: {mutation.data.data.alerts.is_loitering ? 'Yes' : 'No'}</div>
                    <div>Camera Blocked: {mutation.data.data.alerts.is_camera_block ? 'Yes' : 'No'}</div>
                    <div>Scene Changed: {mutation.data.data.alerts.is_scene_change ? 'Yes' : 'No'}</div>
                  </div>
                </div>

                {/* Detections */}
                <div>
                  <h5 className="text-sm font-medium text-neutral-700 dark:text-neutral-300 mb-2">Detections:</h5>
                  <div className="space-y-2">
                    {mutation.data.data.detections.map((detection, index) => (
                      <div key={index} className="flex justify-between items-center text-sm">
                        <span>{detection.label}</span>
                        <span className="text-neutral-500">{Math.round(detection.score * 100)}% confidence</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {mutation.data && (
        <div className="mt-6 rounded-lg border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800 p-4">
          <h3 className="text-lg font-medium text-neutral-900 dark:text-neutral-100 mb-3">Response:</h3>
          <JsonViewer data={mutation.data} />
        </div>
      )}

      {mutation.error && (
        <div className="mt-4 rounded-md bg-red-50 dark:bg-red-900/20 p-4">
          <div className="flex">
            <div className="text-sm text-red-700 dark:text-red-400">
              <p>Error: {(mutation.error as Error).message}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
