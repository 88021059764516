import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface ApiKeyPopupProps {
  isOpen: boolean;
  onClose: () => void;
  apiKey: string;
  name: string;
}

export default function ApiKeyPopup({ isOpen, onClose, apiKey, name }: ApiKeyPopupProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30 dark:bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white dark:bg-neutral-800 p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex items-center justify-between mb-4">
                  <Dialog.Title className="text-lg font-semibold text-neutral-darker dark:text-neutral-lighter">
                    API Key: {name}
                  </Dialog.Title>
                  <button
                    type="button"
                    onClick={onClose}
                    className="rounded-lg p-1 text-neutral hover:text-primary dark:text-neutral-light dark:hover:text-primary-light
                      hover:bg-primary/5 dark:hover:bg-primary/10 transition-all duration-200"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>

                <div className="mt-4">
                  <p className="text-sm text-neutral-dark dark:text-neutral-light mb-2">
                    Make sure to copy your API key and store it securely.
                  </p>
                  <div className="relative">
                    <div className="font-mono text-sm bg-neutral-50 dark:bg-neutral-900 p-3 rounded border 
                      border-neutral/20 break-all text-neutral-darker dark:text-neutral-lighter"
                    >
                      {apiKey}
                    </div>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(apiKey);
                        // You could add a toast notification here
                      }}
                      className="absolute top-2 right-2 px-2 py-1 text-xs font-medium rounded-md
                        bg-primary/10 text-primary hover:bg-primary/20 dark:bg-primary/20 
                        dark:text-primary-light dark:hover:bg-primary/30 transition-colors duration-200"
                    >
                      Copy
                    </button>
                  </div>
                </div>

                <div className="mt-6 flex justify-end">
                  <button
                    type="button"
                    onClick={onClose}
                    className="rounded-lg px-4 py-2 text-sm font-medium bg-primary text-white 
                      hover:bg-primary-dark transition-colors duration-200"
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
