import React, { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import licenseService from '../../services/licenseService';
import { toast } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns';

export default function LicenseManagement() {
  const [licenseKey, setLicenseKey] = useState('');
  const [atmId, setAtmId] = useState('');
  const token = localStorage.getItem('jwt_token') || '';

  // Fetch license status
  const { data: licenseStatus, isLoading, error, refetch } = useQuery({
    queryKey: ['licenseStatus'],
    queryFn: () => licenseService.getLicenseStatus(token),
    refetchInterval: 30000, // Refresh every 30 seconds
  });

  // Activate license mutation
  const activateLicenseMutation = useMutation({
    mutationFn: (key: string) => {
      return licenseService.activateLicense(token, {
        licenseKey: key,
        validityDays: 365,
        maxAtmCount: 10
      });
    },
    onSuccess: () => {
      toast.success('License activated successfully');
      setLicenseKey('');
      refetch();
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Failed to activate license');
    },
  });

  // Register ATM mutation
  const registerAtmMutation = useMutation({
    mutationFn: (id: string) => licenseService.registerATM(token, id),
    onSuccess: () => {
      toast.success('ATM registered successfully');
      setAtmId('');
      refetch();
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.error || 'Failed to register ATM');
    },
  });

  const handleActivateLicense = (e: React.FormEvent) => {
    e.preventDefault();
    if (licenseKey) {
      activateLicenseMutation.mutate(licenseKey);
    }
  };

  const handleRegisterAtm = (e: React.FormEvent) => {
    e.preventDefault();
    if (atmId) {
      registerAtmMutation.mutate(atmId);
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white p-6 rounded-lg shadow-md animate-pulse">
        <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
        <div className="space-y-3">
          <div className="h-4 bg-gray-200 rounded w-3/4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/2"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="text-red-600">
          Failed to load license information. Please try again later.
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="p-6">
        <h3 className="text-lg font-medium text-gray-900">License Management</h3>
        
        {/* License Status */}
        <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div className="bg-gray-50 px-4 py-5 rounded-lg">
            <dt className="text-sm font-medium text-gray-500">License Status</dt>
            <dd className="mt-1 flex items-center">
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                licenseStatus?.isActive 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-red-100 text-red-800'
              }`}>
                {licenseStatus?.isActive ? 'Active' : 'Inactive'}
              </span>
            </dd>
          </div>

          <div className="bg-gray-50 px-4 py-5 rounded-lg">
            <dt className="text-sm font-medium text-gray-500">ATM Usage</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {licenseStatus?.currentAtmCount} / {licenseStatus?.maxAtmCount} ATMs
            </dd>
          </div>

          <div className="bg-gray-50 px-4 py-5 rounded-lg sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">License Expiry</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {licenseStatus?.expiryDate ? (
                <>
                  {new Date(licenseStatus.expiryDate).toLocaleDateString()} 
                  <span className="text-gray-500 ml-2">
                    ({formatDistanceToNow(new Date(licenseStatus.expiryDate), { addSuffix: true })})
                  </span>
                </>
              ) : (
                'No active license'
              )}
            </dd>
          </div>
        </div>

        {/* License Activation Form */}
        <form onSubmit={handleActivateLicense} className="mt-6">
          <div className="flex gap-4">
            <input
              type="text"
              value={licenseKey}
              onChange={(e) => setLicenseKey(e.target.value)}
              placeholder="Enter license key"
              className="flex-1 rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              disabled={!licenseKey || activateLicenseMutation.isPending}
              className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
            >
              {activateLicenseMutation.isPending ? 'Activating...' : 'Activate License'}
            </button>
          </div>
        </form>

        {/* ATM Registration Form */}
        {licenseStatus?.isActive && (
          <form onSubmit={handleRegisterAtm} className="mt-4">
            <div className="flex gap-4">
              <input
                type="text"
                value={atmId}
                onChange={(e) => setAtmId(e.target.value)}
                placeholder="Enter ATM ID"
                className="flex-1 rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="submit"
                disabled={!atmId || registerAtmMutation.isPending || licenseStatus.currentAtmCount >= licenseStatus.maxAtmCount}
                className="rounded-md bg-blue-600 px-4 py-2 text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                {registerAtmMutation.isPending ? 'Registering...' : 'Register ATM'}
              </button>
            </div>
            {licenseStatus.currentAtmCount >= licenseStatus.maxAtmCount && (
              <p className="mt-2 text-sm text-red-600">
                Maximum ATM limit reached. Please upgrade your license to add more ATMs.
              </p>
            )}
          </form>
        )}
      </div>
    </div>
  );
}
