import axios from 'axios';
import { api } from './api';

const BASE_URL = import.meta.env.VITE_API_URL || 'https://monitor.pixuate.ai/api/v1';

export interface ApiKey {
  id: string;
  key: string;
  name: string;
  userId: string;
  status: 'active' | 'disabled';
  permissions: string[];
  usage?: number;
  expiresAt?: string;
  createdAt: string;
  updatedAt: string;
}

export interface CreateApiKeyRequest {
  name: string;
  permissions: string[];
  expiresIn: string;
}

export interface UpdateApiKeyRequest {
  name?: string;
  status?: 'active' | 'disabled';
  permissions?: string[];
}

const apiKeyService = {
  async createApiKey(data: CreateApiKeyRequest): Promise<{ key: string }> {
    const response = await api.post('api-keys', data);
    return response.data;
  },

  async listApiKeys(): Promise<ApiKey[]> {
    const response = await api.get('api-keys');
    return response.data;
  },

  async updateApiKey(id: string, data: UpdateApiKeyRequest): Promise<ApiKey> {
    const response = await api.patch(`api-keys/${id}`, data);
    return response.data;
  },

  async deleteApiKey(id: string): Promise<void> {
    await api.delete(`api-keys/${id}`);
  },

  async revokeApiKey(id: string): Promise<void> {
    await api.post(`api-keys/${id}/revoke`);
  }
};

export default apiKeyService;
