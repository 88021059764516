import React, { useEffect, useState } from 'react';
import { PlusIcon, PencilIcon, TrashIcon, EyeIcon } from '@heroicons/react/24/outline';
import { ApiKey } from '../../services/apiKeyService';
import apiKeyService from '../../services/apiKeyService';
import ApiKeyModal from './ApiKeyModal';
import ApiKeyPopup from './ApiKeyPopup';

interface ApiKeyListProps {
  onEdit?: (apiKey: ApiKey) => void;
}

export default function ApiKeyList({ onEdit }: ApiKeyListProps) {
  const [apiKeys, setApiKeys] = React.useState<ApiKey[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey | null>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [viewKeyPopup, setViewKeyPopup] = useState<{ isOpen: boolean; key: string; name: string }>({
    isOpen: false,
    key: '',
    name: '',
  });

  const fetchApiKeys = async () => {
    try {
      const keys = await apiKeyService.listApiKeys();
      setApiKeys(keys);
    } catch (err) {
      setError('Failed to load API keys');
      console.error('Error fetching API keys:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const handleCreateSuccess = () => {
    fetchApiKeys();
  };

  const handleEdit = (apiKey: ApiKey) => {
    setSelectedApiKey(apiKey);
    setIsModalOpen(true);
  };

  const handleDelete = async (apiKey: ApiKey) => {
    if (!window.confirm(`Are you sure you want to delete the API key "${apiKey.name}"?`)) {
      return;
    }

    setDeleteLoading(true);
    try {
      await apiKeyService.deleteApiKey(apiKey.id);
      await fetchApiKeys();
    } catch (err) {
      setError('Failed to delete API key');
      console.error('Error deleting API key:', err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedApiKey(null);
  };

  const handleViewKey = (key: string, name: string) => {
    setViewKeyPopup({
      isOpen: true,
      key,
      name,
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8">
        <p className="text-error">{error}</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-neutral-darker dark:text-neutral-lighter">API Keys</h3>
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className="inline-flex items-center gap-x-1.5 rounded-lg bg-primary px-3 py-2 text-sm font-semibold text-white 
            shadow-sm hover:bg-primary-dark focus-visible:outline focus-visible:outline-2 
            focus-visible:outline-offset-2 focus-visible:outline-primary transition-all duration-200"
        >
          <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          New API Key
        </button>
      </div>

      <div className="mt-6 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-primary/5 dark:ring-primary/10 sm:rounded-lg">
              <table className="min-w-full divide-y divide-primary/10 dark:divide-primary/20">
                <thead className="bg-primary/5 dark:bg-primary/10">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-neutral-darker dark:text-neutral-lighter sm:pl-6">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-darker dark:text-neutral-lighter">
                      Key
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-darker dark:text-neutral-lighter">
                      Permissions
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-darker dark:text-neutral-lighter">
                      Usage
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-darker dark:text-neutral-lighter">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-darker dark:text-neutral-lighter">
                      Expires
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-primary/10 dark:divide-primary/20 bg-white dark:bg-neutral-900">
                  {apiKeys.map((apiKey) => (
                    <tr key={apiKey.id} className="hover:bg-primary/5 dark:hover:bg-primary/10 transition-colors duration-200">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-neutral-darker dark:text-neutral-lighter sm:pl-6">
                        {apiKey.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-mono text-neutral dark:text-neutral-lighter">
                        <div className="flex items-center gap-2">
                          <span>••••••••</span>
                          <button
                            onClick={() => handleViewKey(apiKey.key, apiKey.name)}
                            className="p-1 rounded-lg hover:bg-primary/5 dark:hover:bg-primary/10 text-neutral dark:text-neutral-light
                              transition-colors duration-200"
                          >
                            <EyeIcon className="h-4 w-4" />
                            <span className="sr-only">View API Key</span>
                          </button>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral dark:text-neutral-lighter">
                        <div className="flex gap-1 flex-wrap">
                          {apiKey.permissions.map((permission) => (
                            <span
                              key={permission}
                              className="inline-flex items-center rounded-md bg-primary/10 dark:bg-primary/20 
                                px-2 py-1 text-xs font-medium text-primary dark:text-primary-light"
                            >
                              {permission}
                            </span>
                          ))}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral dark:text-neutral-lighter">
                        {apiKey.usage || 0} calls
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm">
                        <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium
                          ${apiKey.status === 'active'
                            ? 'bg-success-lighter dark:bg-success/10 text-success'
                            : 'bg-error-lighter dark:bg-error/10 text-error'
                          }`}
                        >
                          {apiKey.status}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral dark:text-neutral-lighter">
                        {apiKey.expiresAt ? new Date(apiKey.expiresAt).toLocaleDateString() : 'Never'}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <div className="flex justify-end gap-2">
                          <button
                            onClick={() => handleEdit(apiKey)}
                            disabled={deleteLoading}
                            className="rounded-lg p-1 text-neutral hover:text-primary dark:text-neutral-light dark:hover:text-primary-light
                              hover:bg-primary/5 dark:hover:bg-primary/10 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            <PencilIcon className="h-4 w-4" />
                            <span className="sr-only">Edit</span>
                          </button>
                          <button
                            onClick={() => handleDelete(apiKey)}
                            disabled={deleteLoading}
                            className="rounded-lg p-1 text-neutral hover:text-error dark:text-neutral-light dark:hover:text-error
                              hover:bg-error/5 dark:hover:bg-error/10 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                          >
                            <TrashIcon className="h-4 w-4" />
                            <span className="sr-only">Delete</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ApiKeyModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSuccess={handleCreateSuccess}
        apiKey={selectedApiKey}
      />

      <ApiKeyPopup
        isOpen={viewKeyPopup.isOpen}
        onClose={() => setViewKeyPopup({ isOpen: false, key: '', name: '' })}
        apiKey={viewKeyPopup.key}
        name={viewKeyPopup.name}
      />
    </div>
  );
}
