import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

const BASE_URL = import.meta.env.VITE_API_URL ? `${import.meta.env.VITE_API_URL}/api/v1` : 'https://monitor.pixuate.ai/api/v1';
const API_KEY = import.meta.env.VITE_API_KEY || 'ace3026290af7cd3481f68e44af6fc6280e05139e22f1e81a245b7e34dce88f3';

// Define custom interface for request config with retry
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

// Token management
const getStoredToken = () => {
  const token = sessionStorage.getItem('token') || localStorage.getItem('token');
  return token ? `Bearer ${token}` : null;
};

const setStoredToken = (token: string) => {
  // Store the raw token without 'Bearer ' prefix
  const cleanToken = token.replace('Bearer ', '');
  sessionStorage.setItem('token', cleanToken);
  localStorage.setItem('token', cleanToken);
  // Update Authorization header
  api.defaults.headers.common['Authorization'] = `Bearer ${cleanToken}`;
};

const removeStoredToken = () => {
  sessionStorage.removeItem('token');
  localStorage.removeItem('token');
  delete api.defaults.headers.common['Authorization'];
};

// Create axios instance with multipart/form-data support
export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Accept': 'application/json, text/plain, */*',
  },
  withCredentials: false, // Set to false for local development
});

// Initialize Authorization header if token exists
const initialToken = getStoredToken();
if (initialToken) {
  api.defaults.headers.common['Authorization'] = initialToken;
}

// Request interceptor for adding auth token
api.interceptors.request.use(
  (config) => {
    console.log('Making request to:', config.url);
    const token = getStoredToken();
    if (token && config.headers) {
      config.headers.Authorization = token;
    }
    
    // Set the correct Content-Type for multipart/form-data
    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    } else {
      config.headers['Content-Type'] = 'application/json';
    }
    
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor for handling token refresh
api.interceptors.response.use(
  (response) => {
    console.log('Response from:', response.config.url, response.status);
    const newToken = response.headers?.authorization;
    if (newToken) {
      setStoredToken(newToken);
    }
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as CustomAxiosRequestConfig;
    console.error('Response error:', {
      url: originalRequest?.url,
      status: error.response?.status,
      error: error.message
    });

    if (error.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;

      try {
        const response = await axios.post(
          `${BASE_URL}/api/auth/refresh`,
          {},
          { 
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          }
        );

        const newToken = response.data.token;
        if (newToken) {
          setStoredToken(newToken);
          if (originalRequest.headers) {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
          }
          return axios(originalRequest);
        }
      } catch (refreshError) {
        removeStoredToken();
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// Create a separate instance for detection API with API key
const detectionApiInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  }
});

// Request interceptor for detection API to handle FormData and API key
detectionApiInstance.interceptors.request.use(
  (config) => {
    console.log('Making request to:', config.url);
    // Always add the API key to the URL for detection endpoints
    if (config.url?.includes('/api/v1/detection')) {
      const separator = config.url.includes('?') ? '&' : '?';
      config.url = `${config.url}${separator}apiKey=${API_KEY}`;
    }

    if (config.data instanceof FormData) {
      config.headers['Content-Type'] = 'multipart/form-data';
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Helper function for detection API
export const detectionApi = {
  createDetection: async (data: FormData) => {
    if (!API_KEY) {
      throw new Error('API key is not configured');
    }
    return detectionApiInstance.post('/api/v1/detection', data);
  },
  
  getDetections: async (params: { 
    startDate?: string; 
    endDate?: string; 
    page?: number; 
    limit?: number; 
  }) => {
    if (!API_KEY) {
      throw new Error('API key is not configured');
    }
    return detectionApiInstance.get('/api/v1/detection', { params });
  }
};

export default api;
