import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { Switch } from '@headlessui/react';
import { api } from '../services/api';
import MonitoringGrid from '../components/monitoring/MonitoringGrid';
import LiveMonitoringGrid from '../components/monitoring/LiveMonitoringGrid';
import { MonitoringData } from '../types';
import { socketService } from '../services/socketService';

export default function Monitoring() {
  const [isMonitoringEnabled, setIsMonitoringEnabled] = useState(false);

  const { data: monitoringData, isLoading, isError, error } = useQuery<MonitoringData[]>({
    queryKey: ['monitoring'],
    queryFn: async () => {
      try {
        const response = await api.get('/monitoring/status');
        // Ensure the response data is an array
        const data = Array.isArray(response.data) ? response.data : [];
        // Validate each item in the array
        return data.map(item => ({
          id: item.id || '',
          atmId: item.atmId || '',
          status: item.status || 'inactive',
          lastUpdate: item.lastUpdate || new Date().toISOString(),
          alerts: item.alerts || 0,
          image: item.image || null,
          location: item.location || '',
          updatedAt: item.updatedAt || new Date().toISOString(),
          incidents: Array.isArray(item.incidents) ? item.incidents : []
        }));
      } catch (err) {
        console.error('Error fetching monitoring data:', err);
        throw new Error('Failed to fetch monitoring data. Please try again later.');
      }
    },
    refetchInterval: 30000, // Refetch every 30 seconds
    retry: 3, // Retry failed requests up to 3 times
    retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff
  });

  const handleMonitoringToggle = (enabled: boolean) => {
    setIsMonitoringEnabled(enabled);
    if (enabled) {
      socketService.enableMonitoring('all');
    } else {
      socketService.disableMonitoring('all');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-background-light">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex items-center justify-center min-h-[400px]">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="min-h-screen bg-background-light">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="bg-red-50 p-4 rounded-md">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error loading monitoring data</h3>
                <div className="mt-2 text-sm text-red-700">
                  {error instanceof Error ? error.message : 'Please try again later'}
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    onClick={() => window.location.reload()}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200"
                  >
                    Retry
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const safeMonitoringData = Array.isArray(monitoringData) ? monitoringData : [];

  return (
    <div className="min-h-screen bg-background-light">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-bold text-primary-darker">ATM Monitoring</h1>
            <p className="mt-2 text-sm text-primary-dark">
              Real-time monitoring of your ATM network
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Switch.Group as="div" className="flex items-center">
              <Switch
                checked={isMonitoringEnabled}
                onChange={handleMonitoringToggle}
                className={`${
                  isMonitoringEnabled ? 'bg-primary' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`}
              >
                <span
                  className={`${
                    isMonitoringEnabled ? 'translate-x-5' : 'translate-x-0'
                  } pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                >
                  <span
                    className={`${
                      isMonitoringEnabled ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in'
                    } absolute inset-0 flex h-full w-full items-center justify-center transition-opacity`}
                    aria-hidden="true"
                  >
                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    className={`${
                      isMonitoringEnabled ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out'
                    } absolute inset-0 flex h-full w-full items-center justify-center transition-opacity`}
                    aria-hidden="true"
                  >
                    <svg className="h-3 w-3 text-primary" fill="currentColor" viewBox="0 0 12 12">
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <span className="text-sm font-medium text-gray-900">Live Monitoring</span>
              </Switch.Label>
            </Switch.Group>
          </div>
        </div>

        {isMonitoringEnabled ? (
          <div className="mb-8">
            <LiveMonitoringGrid isMonitoringEnabled={isMonitoringEnabled} />
          </div>
        ) : null}

        {safeMonitoringData.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <h3 className="mt-2 text-sm font-medium text-gray-900">No ATMs to monitor</h3>
            <p className="mt-1 text-sm text-gray-500">Add ATMs to start monitoring their status.</p>
          </div>
        ) : (
          <MonitoringGrid data={safeMonitoringData} />
        )}
      </div>
    </div>
  );
}
