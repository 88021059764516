import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  ClockIcon,
  MapPinIcon,
  UserGroupIcon,
  ShieldCheckIcon,
  FaceSmileIcon,
  ExclamationTriangleIcon,
  CameraIcon,
  MagnifyingGlassIcon,
  FunnelIcon,
  CalendarIcon,
  BuildingLibraryIcon,
} from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { logService, AtmLog, LogsResponse } from '../services/logService';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

interface LogFilters {
  startDate?: Date;
  endDate?: Date;
  atmId?: string;
  location?: string;
  searchTerm?: string;
  alertTypes: {
    loitering: boolean;
    cameraBlock: boolean;
    sceneChange: boolean;
  };
}

type AlertType = 'loitering' | 'cameraBlock' | 'sceneChange';

export default function Logs() {
  const [filters, setFilters] = useState<LogFilters>({
    alertTypes: {
      loitering: false,
      cameraBlock: false,
      sceneChange: false,
    }
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLog, setSelectedLog] = useState<AtmLog | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const pageSize = 10;

  const { data: logs, isLoading } = useQuery<LogsResponse>({
    queryKey: ['logs', filters, currentPage],
    queryFn: () => logService.getLogs({
      ...filters,
      page: currentPage,
      limit: pageSize,
    }),
  });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(prev => ({ ...prev, searchTerm: e.target.value }));
    setCurrentPage(1);
  };

  const handleDateChange = (field: 'startDate' | 'endDate') => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters(prev => ({ ...prev, [field]: e.target.value ? new Date(e.target.value) : undefined }));
    setCurrentPage(1);
  };

  const handleAlertTypeChange = (type: AlertType) => {
    setFilters(prev => ({
      ...prev,
      alertTypes: {
        ...prev.alertTypes,
        [type]: !prev.alertTypes[type],
      },
    }));
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setFilters({
      alertTypes: {
        loitering: false,
        cameraBlock: false,
        sceneChange: false,
      }
    });
    setCurrentPage(1);
  };

  const getStatusIcon = (value: number) => {
    return value === 1 ? (
      <div className="flex items-center text-red-600 dark:text-red-400">
        <ExclamationTriangleIcon className="h-5 w-5 mr-1" />
        Yes
      </div>
    ) : (
      <div className="flex items-center text-green-600 dark:text-green-400">
        <ShieldCheckIcon className="h-5 w-5 mr-1" />
        No
      </div>
    );
  };

  const renderLogCard = (log: AtmLog) => (
    <div key={log.id} className="bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 overflow-hidden">
      <div className="p-4">
        <div className="flex justify-between items-start mb-4">
          <div>
            <div className="flex items-center gap-2 mb-2">
              <BuildingLibraryIcon className="h-5 w-5 text-indigo-500" />
              <span className="font-medium text-gray-900 dark:text-gray-100">ATM ID: {log.atmId}</span>
            </div>
            <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
              <MapPinIcon className="h-4 w-4" />
              <span>{log.location}</span>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
              <CalendarIcon className="h-4 w-4" />
              <span>{format(new Date(log.createdAt), 'MMM d, yyyy')}</span>
            </div>
            <div className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
              <ClockIcon className="h-4 w-4" />
              <span>{format(new Date(log.createdAt), 'HH:mm')}</span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4 mb-4">
          <div className={`flex flex-col items-center p-3 rounded-lg ${
            log.personCount > 0 ? 'bg-blue-50 dark:bg-blue-900/20' : 'bg-gray-50 dark:bg-gray-900'
          }`}>
            <UserGroupIcon className={`h-8 w-8 mb-1 ${
              log.personCount > 0 ? 'text-blue-500' : 'text-gray-400'
            }`} />
            <span className="text-2xl font-bold text-gray-900 dark:text-gray-100">{log.personCount}</span>
            <span className="text-xs text-gray-600 dark:text-gray-400">People Detected</span>
          </div>
          <div className={`flex flex-col items-center p-3 rounded-lg ${
            log.helmetCount > 0 ? 'bg-green-50 dark:bg-green-900/20' : 'bg-gray-50 dark:bg-gray-900'
          }`}>
            <ShieldCheckIcon className={`h-8 w-8 mb-1 ${
              log.helmetCount > 0 ? 'text-green-500' : 'text-gray-400'
            }`} />
            <span className="text-2xl font-bold text-gray-900 dark:text-gray-100">{log.helmetCount}</span>
            <span className="text-xs text-gray-600 dark:text-gray-400">Helmets</span>
          </div>
          <div className={`flex flex-col items-center p-3 rounded-lg ${
            log.maskCount > 0 ? 'bg-purple-50 dark:bg-purple-900/20' : 'bg-gray-50 dark:bg-gray-900'
          }`}>
            <FaceSmileIcon className={`h-8 w-8 mb-1 ${
              log.maskCount > 0 ? 'text-purple-500' : 'text-gray-400'
            }`} />
            <span className="text-2xl font-bold text-gray-900 dark:text-gray-100">{log.maskCount}</span>
            <span className="text-xs text-gray-600 dark:text-gray-400">Masks</span>
          </div>
        </div>

        <div className="space-y-3">
          <div className={`flex items-center justify-between p-3 rounded-lg ${
            log.isLoitering ? 'bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500' : 'bg-gray-50 dark:bg-gray-900'
          }`}>
            <div className="flex items-center gap-2">
              <ExclamationTriangleIcon className={`h-5 w-5 ${
                log.isLoitering ? 'text-red-500' : 'text-gray-400'
              }`} />
              <span className={`font-medium ${
                log.isLoitering ? 'text-red-700 dark:text-red-400' : 'text-gray-700 dark:text-gray-400'
              }`}>Loitering</span>
            </div>
            <div className={`px-2 py-1 rounded-full text-xs font-medium ${
              log.isLoitering 
                ? 'bg-red-100 text-red-800 dark:bg-red-900/40 dark:text-red-400'
                : 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-400'
            }`}>
              {log.isLoitering ? 'Detected' : 'Clear'}
            </div>
          </div>

          <div className={`flex items-center justify-between p-3 rounded-lg ${
            log.isCameraBlock ? 'bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500' : 'bg-gray-50 dark:bg-gray-900'
          }`}>
            <div className="flex items-center gap-2">
              <CameraIcon className={`h-5 w-5 ${
                log.isCameraBlock ? 'text-red-500' : 'text-gray-400'
              }`} />
              <span className={`font-medium ${
                log.isCameraBlock ? 'text-red-700 dark:text-red-400' : 'text-gray-700 dark:text-gray-400'
              }`}>Camera Block</span>
            </div>
            <div className={`px-2 py-1 rounded-full text-xs font-medium ${
              log.isCameraBlock 
                ? 'bg-red-100 text-red-800 dark:bg-red-900/40 dark:text-red-400'
                : 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-400'
            }`}>
              {log.isCameraBlock ? 'Detected' : 'Clear'}
            </div>
          </div>

          <div className={`flex items-center justify-between p-3 rounded-lg ${
            log.isSceneChange ? 'bg-red-50 dark:bg-red-900/20 border-l-4 border-red-500' : 'bg-gray-50 dark:bg-gray-900'
          }`}>
            <div className="flex items-center gap-2">
              <ExclamationTriangleIcon className={`h-5 w-5 ${
                log.isSceneChange ? 'text-red-500' : 'text-gray-400'
              }`} />
              <span className={`font-medium ${
                log.isSceneChange ? 'text-red-700 dark:text-red-400' : 'text-gray-700 dark:text-gray-400'
              }`}>Scene Change</span>
            </div>
            <div className={`px-2 py-1 rounded-full text-xs font-medium ${
              log.isSceneChange 
                ? 'bg-red-100 text-red-800 dark:bg-red-900/40 dark:text-red-400'
                : 'bg-gray-100 text-gray-800 dark:bg-gray-800 dark:text-gray-400'
            }`}>
              {log.isSceneChange ? 'Detected' : 'Clear'}
            </div>
          </div>
        </div>

        {log.imagePath && (
          <div className="mt-4">
            <button
              onClick={() => setSelectedLog(log)}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300 border border-indigo-200 dark:border-indigo-800 rounded-lg hover:bg-indigo-50 dark:hover:bg-indigo-900/20 transition-colors duration-200"
            >
              <CameraIcon className="h-5 w-5" />
              View Detection Image
            </button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
          <div className="relative flex-1">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search by location or ATM ID..."
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white dark:bg-gray-800 dark:border-gray-700 placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={handleSearch}
              value={filters.searchTerm || ''}
            />
          </div>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FunnelIcon className="h-5 w-5 mr-2" />
            Filters {Object.values(filters.alertTypes).some(v => v) && '(Active)'}
          </button>
        </div>

        {showFilters && (
          <div className="mt-4 p-4 bg-gray-50 dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-700">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Date Range</label>
                <div className="mt-1 space-y-2">
                  <input
                    type="date"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600"
                    onChange={handleDateChange('startDate')}
                    value={filters.startDate?.toISOString().split('T')[0] || ''}
                  />
                  <input
                    type="date"
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600"
                    onChange={handleDateChange('endDate')}
                    value={filters.endDate?.toISOString().split('T')[0] || ''}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Alert Types</label>
                <div className="mt-2 space-y-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      checked={filters.alertTypes.loitering}
                      onChange={() => handleAlertTypeChange('loitering')}
                    />
                    <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">Loitering</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      checked={filters.alertTypes.cameraBlock}
                      onChange={() => handleAlertTypeChange('cameraBlock')}
                    />
                    <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">Camera Block</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      checked={filters.alertTypes.sceneChange}
                      onChange={() => handleAlertTypeChange('sceneChange')}
                    />
                    <span className="ml-2 text-sm text-gray-700 dark:text-gray-300">Scene Change</span>
                  </label>
                </div>
              </div>

              <div className="flex items-end">
                <button
                  onClick={resetFilters}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200 dark:hover:bg-gray-600"
                >
                  Reset Filters
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {isLoading ? (
          <div className="col-span-full flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
          </div>
        ) : logs?.data.length === 0 ? (
          <div className="col-span-full flex flex-col items-center justify-center h-64 text-gray-500 dark:text-gray-400">
            <FunnelIcon className="h-12 w-12 mb-4" />
            <p className="text-lg">No logs found</p>
            <p className="text-sm">Try adjusting your filters</p>
          </div>
        ) : (
          logs?.data.map(renderLogCard)
        )}
      </div>

      <div className="mt-6 flex items-center justify-between">
        <div>
          <p className="text-sm text-gray-700 dark:text-gray-300">
            Showing <span className="font-medium">{(currentPage - 1) * pageSize + 1}</span> to{' '}
            <span className="font-medium">
              {Math.min(currentPage * pageSize, logs?.total || 0)}
            </span>{' '}
            of <span className="font-medium">{logs?.total || 0}</span> results
          </p>
        </div>
        <div className="flex gap-2">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-md text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={!logs?.data.length || logs?.data.length < pageSize}
            className="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-md text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>

      {selectedLog && (
        <Transition appear show={true} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            onClose={() => setSelectedLog(null)}
          >
            <div className="min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-3xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl">
                  <Dialog.Title className="text-lg font-medium mb-4 text-gray-900 dark:text-gray-100">
                    Detection Details
                  </Dialog.Title>

                  <div className="space-y-4">
                    {selectedLog.imagePath && (
                      <div className="relative h-96">
                        <img
                          className="w-full h-full object-contain rounded-lg"
                          src={selectedLog.imagePath}
                          alt="Detection"
                        />
                      </div>
                    )}

                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <BuildingLibraryIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-900 dark:text-gray-100">ATM ID: {selectedLog.atmId}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <MapPinIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-900 dark:text-gray-100">Location: {selectedLog.location}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <CalendarIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-900 dark:text-gray-100">
                            {format(new Date(selectedLog.createdAt), 'PPpp')}
                          </span>
                        </div>
                      </div>

                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <UserGroupIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-900 dark:text-gray-100">People: {selectedLog.personCount}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <ShieldCheckIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-900 dark:text-gray-100">Helmets: {selectedLog.helmetCount}</span>
                        </div>
                        <div className="flex items-center gap-2">
                          <FaceSmileIcon className="h-5 w-5 text-gray-500" />
                          <span className="text-gray-900 dark:text-gray-100">Masks: {selectedLog.maskCount}</span>
                        </div>
                      </div>
                    </div>

                    <div className="space-y-2 mt-4">
                      <div className={`flex items-center justify-between p-2 rounded ${
                        selectedLog.isLoitering ? 'bg-red-50 dark:bg-red-900/20' : 'bg-gray-50 dark:bg-gray-900'
                      }`}>
                        <span>Loitering</span>
                        {getStatusIcon(selectedLog.isLoitering)}
                      </div>
                      <div className={`flex items-center justify-between p-2 rounded ${
                        selectedLog.isCameraBlock ? 'bg-red-50 dark:bg-red-900/20' : 'bg-gray-50 dark:bg-gray-900'
                      }`}>
                        <span>Camera Block</span>
                        {getStatusIcon(selectedLog.isCameraBlock)}
                      </div>
                      <div className={`flex items-center justify-between p-2 rounded ${
                        selectedLog.isSceneChange ? 'bg-red-50 dark:bg-red-900/20' : 'bg-gray-50 dark:bg-gray-900'
                      }`}>
                        <span>Scene Change</span>
                        {getStatusIcon(selectedLog.isSceneChange)}
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex justify-end">
                    <button
                      onClick={() => setSelectedLog(null)}
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 border border-transparent rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      )}
    </div>
  );
}
