import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import { BellIcon, UserCircleIcon, SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../context/AuthContext';

export default function TopBar() {
  const { user, logout } = useAuth();
  const [isDark, setIsDark] = React.useState(() => 
    document.documentElement.classList.contains('dark')
  );

  const toggleTheme = () => {
    setIsDark(!isDark);
    if (isDark) {
      document.documentElement.classList.remove('dark');
      localStorage.theme = 'light';
    } else {
      document.documentElement.classList.add('dark');
      localStorage.theme = 'dark';
    }
  };

  return (
    <div className="sticky top-0 z-40 hidden lg:block">
      <div className="absolute inset-0 h-full bg-white/80 dark:bg-neutral-900/80 backdrop-blur-sm" aria-hidden="true" />
      <div className="relative border-b border-primary/10">
        <div className="flex h-16 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="flex flex-1 items-center gap-x-4 lg:gap-x-6">
              {/* Search */}
              <div className="relative flex flex-1 max-w-md">
                <input
                  type="text"
                  placeholder="Quick search..."
                  className="block w-full rounded-xl border-0 
                    bg-white/50 dark:bg-neutral-800/50 py-2.5 pl-11 pr-4
                    text-neutral-900 dark:text-neutral-100 
                    ring-1 ring-inset ring-primary/10 dark:ring-primary/5
                    placeholder:text-neutral-500 dark:placeholder:text-neutral-400 
                    focus:ring-2 focus:ring-inset focus:ring-primary dark:focus:ring-primary-light
                    hover:ring-primary/20 dark:hover:ring-primary/10
                    hover:bg-white/80 dark:hover:bg-neutral-800/80
                    shadow-sm hover:shadow-md
                    transition-all duration-200"
                />
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                  <svg className="h-5 w-5 text-primary/60 dark:text-primary-light/60" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>

              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* Theme Toggle */}
                <button
                  type="button"
                  onClick={toggleTheme}
                >
                  <span className="sr-only">Toggle theme</span>
                  {isDark ? (
                    <SunIcon className="h-5 w-5" />
                  ) : (
                    <MoonIcon className="h-5 w-5" />
                  )}
                </button>

                {/* Notifications */}
                <button
                  type="button"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-5 w-5" />
                  {/* Notification badge */}
                  <span className="absolute -top-0.5 -right-0.5 flex h-4 w-4 items-center justify-center rounded-full bg-primary text-[10px] font-medium text-white ring-2 ring-white dark:ring-neutral-900">
                    2
                  </span>
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button>
                    <span className="sr-only">Open user menu</span>
                    <UserCircleIcon className="h-5 w-5" />
                  </Menu.Button>
                  <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-xl bg-white dark:bg-neutral-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#profile"
                            className={active ? 'bg-neutral-50 dark:bg-neutral-700' : ''}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#settings"
                            className={active ? 'bg-neutral-50 dark:bg-neutral-700' : ''}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={logout}
                            className={active ? 'bg-neutral-50 dark:bg-neutral-700' : ''}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
