import React from 'react';
import { Tab } from '@headlessui/react';
import { classNames } from '../utils/styles';
import { BeakerIcon } from '@heroicons/react/24/outline';
import DetectionTester from '../components/api-testing/DetectionTester';
import SceneChangeTester from '../components/api-testing/SceneChangeTester';
import AtmMonitorTester from '../components/api-testing/AtmMonitorTester';

const tabs = [
  { name: 'Detection', component: DetectionTester },
  { name: 'Scene Change', component: SceneChangeTester },
  { name: 'ATM Monitor', component: AtmMonitorTester },
];

export default function ApiTesting() {
  return (
    <div className="min-h-screen bg-white dark:bg-neutral-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <div className="flex items-center gap-3 mb-2">
              <BeakerIcon className="h-8 w-8 text-primary-600 dark:text-primary-400" />
              <h1 className="text-3xl font-bold tracking-tight text-neutral-900 dark:text-white">
                API Testing
              </h1>
            </div>
            <p className="mt-2 text-base text-neutral-600 dark:text-neutral-400">
              Test the ATM monitoring APIs with image uploads and view responses in real-time
            </p>
          </div>
        </div>

        <div className="bg-white dark:bg-neutral-800 shadow-xl dark:shadow-2xl rounded-xl border border-neutral-200 dark:border-neutral-700">
          <Tab.Group>
            <Tab.List>
              <div className="px-6">
                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.name}
                    >
                      {tab.name}
                    </Tab>
                  ))}
                </nav>
              </div>
            </Tab.List>
            <Tab.Panels>
              {tabs.map((tab) => (
                <Tab.Panel 
                  key={tab.name}
                  className="p-6 focus:outline-none"
                >
                  <tab.component />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
}
